import { ReactElement, useEffect, useReducer, useState } from 'react';
import {
  BluesnapCheckoutContext,
  CHECKOUT_ELEMENT
} from '@fpc/reactutils/checkoutContextProvider';
import { TechnicalError } from '@fpc/common';
import { CheckoutLoadError } from '../CheckoutLoadError';
import {
  ErrorCondition,
  errorReducer,
  initialErrorState
} from '@fpc/common/ErrorHandler';
import { isNotProd } from '../../flags';
import { createBluesnapPaymentToken } from '@fpc/api/paymentapp/CreateBluesnapPaymentToken';
import { BlueSnapAuthCheckout } from './BlueSnapAuthCheckout';
import { BlueSnapGuestCheckout } from './BlueSnapGuestCheckout';
import { dispatchAuthorizationFailureEvent } from '@fpc/utils/dispatchEvent';
import { getPaymentMethods } from '@fpc/api/customerapp';
import { Psp } from '@fpc/common/Psp';
import { PaymentMethodInfo } from '@fpc/api/paymentapp';
import {
  CommonCheckoutProps,
  logMissingParamError,
  validateCheckout
} from '../CheckoutCommon';
import { CheckoutLoadWrapper } from '../CheckoutLoadWrapper';
import { getCountryFromLocale } from '@fpc/utils/getCountryFromLocale';
import { isValidJsonPaymentInfoForDigitalSign } from '@fpc/utils/paymentInfoJsonValidator';

export function BluesnapCheckoutWrapper(props: CommonCheckoutProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, errorDispatch] = useReducer(errorReducer, initialErrorState);

  const [fordCustomerId, setFordCustomerId] = useState('');
  const [bluesnapCustomerId, setBluesnapCustomerId] = useState<number | null>(
    null
  );
  const [cards, setCards] = useState<PaymentMethodInfo[] | null>(null);
  const [bluesnapPaymentToken, setBluesnapPaymentToken] = useState<string>('');

  const { isValidCheckout, isAuthCheckout } = validateCheckout(props);

  useEffect(() => {
    if (!isValidCheckout) {
      setLoading(false);
    }
  }, [isValidCheckout]);

  useEffect(() => {
    errorDispatch(ErrorCondition.Reset);
    getCheckoutContext();
  }, [props]);

  function handleErrorDispatch(err: any) {
    if (err.unrecoverable) {
      errorDispatch(ErrorCondition.Unrecoverable);
      dispatchAuthorizationFailureEvent(CHECKOUT_ELEMENT);
    }
  }

  function getCheckoutContext() {
    let signature, paymentInfo;
    if (isValidJsonPaymentInfoForDigitalSign(props.paymentInfoToken)) {
      signature = JSON.parse(props.paymentInfoToken).signature;
      paymentInfo = JSON.parse(props.paymentInfoToken).paymentInfo;
    }
    createBluesnapPaymentToken(
      props.bearerToken,
      props.bffBaseUrl,
      signature,
      paymentInfo
    )
      .then((response) => {
        setBluesnapPaymentToken(response);
        if (isAuthCheckout) {
          getPaymentMethods(
            props.bearerToken!,
            props.customerAuthToken!,
            props.customerAuthTokenType,
            props.bffBaseUrl ?? '',
            null,
            Psp.BLUESNAP
          )
            .then((paymentMethodsResponse) => {
              setCards(paymentMethodsResponse.paymentMethods);
              setFordCustomerId(paymentMethodsResponse.fordCustomerId);
              setBluesnapCustomerId(
                parseInt(paymentMethodsResponse.pspCustomerId)
              );
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        handleErrorDispatch(err);
        errorDispatch(ErrorCondition.Present);
        setLoading(false);
      });
  }

  function resetFromError() {
    errorDispatch(ErrorCondition.Reset);
    setLoading(true);
    setFordCustomerId('');
    getCheckoutContext();
  }

  function guestCheckout(): ReactElement {
    if (bluesnapPaymentToken != null) {
      return (
        <BluesnapCheckoutContext.Provider
          value={{
            tokens: props,
            redirectUrl: props.redirectUrl,
            errorDispatch,
            merchantCountry: getCountryFromLocale(props.language!),
            bffBaseUrl: props.bffBaseUrl ?? '',
            transaction: props.transactionDetails
          }}
        >
          <BlueSnapGuestCheckout bluesnapPaymentToken={bluesnapPaymentToken} />
        </BluesnapCheckoutContext.Provider>
      );
    } else {
      return <></>;
    }
  }

  function authCheckout(): ReactElement {
    if (
      bluesnapPaymentToken != null &&
      cards != null &&
      bluesnapCustomerId != null
    ) {
      return (
        <BluesnapCheckoutContext.Provider
          value={{
            tokens: props,
            redirectUrl: props.redirectUrl,
            errorDispatch,
            merchantCountry: getCountryFromLocale(props.language!),
            bffBaseUrl: props.bffBaseUrl ?? '',
            transaction: props.transactionDetails
          }}
        >
          <BlueSnapAuthCheckout
            bluesnapPaymentToken={bluesnapPaymentToken}
            cards={cards}
            bluesnapCustomerId={bluesnapCustomerId}
            fordCustomerId={fordCustomerId}
          />
        </BluesnapCheckoutContext.Provider>
      );
    } else {
      return guestCheckout();
    }
  }

  function getCheckout(): ReactElement {
    if (isAuthCheckout) {
      return authCheckout();
    } else if (isValidCheckout) {
      return guestCheckout();
    } else {
      logMissingParamError(
        props.paymentInfoToken,
        props.redirectUrl,
        props.bearerToken!
      );
      if (isNotProd()) {
        return (
          <CheckoutLoadError
            redirectUrl={props.redirectUrl}
            bearerToken={props.bearerToken!}
            paymentInfoToken={props.paymentInfoToken}
            customerAuthToken={props.customerAuthToken}
            customerAuthTokenType={props.customerAuthTokenType}
            isPreAuth={false}
            bffBaseUrl={props.bffBaseUrl}
            isValidAuthParams={true}
            language={props.language}
          />
        );
      } else {
        return (
          <TechnicalError
            resetFromError={resetFromError}
            unrecoverable={true}
          />
        );
      }
    }
  }

  return (
    <CheckoutLoadWrapper
      error={error}
      resetFromError={resetFromError}
      loading={loading}
      checkoutComponent={getCheckout()}
    />
  );
}
