export const SUPPORTED_CARDS_FOR_SA = ['VISA', 'MASTERCARD'];

export const SUPPORTED_CARDS_FOR_VN = ['VISA', 'MASTERCARD'];

export const PAYU_SUPPORTED_CARDS_BY_CURRENCY: { [key: string]: string[] } = {
  ZAR: SUPPORTED_CARDS_FOR_SA,
  VND: SUPPORTED_CARDS_FOR_VN
};

export const CHECKOUT_REDIRECTION: string = 'CHECKOUT_REDIRECTION';

export const CHECKOUT_FORM: string = 'CHECKOUT_FORM';

const getBooleanEnvVar = (
  name: string,
  defaultValue: boolean = false
): boolean => {
  const value = process.env[name];
  return value ? value.toLowerCase() === 'true' : defaultValue;
};

export const CARD_CHECKOUT_FLOW_BY_CURRENCY: { [key: string]: string } = {
  ZAR: CHECKOUT_FORM,
  VND: CHECKOUT_REDIRECTION
};

export const getCheckoutFlowByCurrency = (currency: string): string => {
  return CARD_CHECKOUT_FLOW_BY_CURRENCY[currency.toUpperCase()];
};
