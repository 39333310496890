import { isNotProd } from '../../flags';

export function enterCustomAction(name: string): number {
  return (
    window.dtrum?.enterAction(
      name,
      undefined,
      undefined,
      window.location.href
    ) ?? 0
  );
}

export function leaveCustomAction(
  actionId: number,
  actionProperties: Record<string, string>
) {
  window.dtrum?.addActionProperties(
    actionId,
    undefined,
    undefined,
    actionProperties
  );

  window.dtrum?.leaveAction(actionId);
}

export function reportCustomError(
  actionId: number,
  name: string,
  description: string
) {
  window.dtrum?.reportCustomError(name, description, '', actionId);
}

export function log(message: string) {
  if (isNotProd()) {
    console.log(message);
  }
}
