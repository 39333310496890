import React, { useState, useContext } from 'react';
import { CheckoutLoader } from '@fpc/checkout/payu/components/CheckoutLoader';
import {
  CHECKOUT_ELEMENT,
  PayuCheckoutContext
} from '@fpc/reactutils/checkoutContextProvider';
import { dispatchAuthorizationFailureEvent } from '@fpc/utils/dispatchEvent';
import { PayuPaymentRequest } from '@fpc/api/paymentapp/MakePayuPayment';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import i18next from 'i18next';
import { translationKeys } from '@fpc/common';
import { makePayment } from '@fpc/checkout/payu/PayuService';
import { CheckoutForm } from '@fpc/checkout/payu/components/CheckoutForm';
import { CheckoutContinueButton } from '@fpc/checkout/payu/components/CheckoutContinueButton';
import PaymentErrorMessage from '@fpc/checkout/payu/components/PaymentErrorMessage';

export function Payu() {
  const { errorDispatch } = useContext(PayuCheckoutContext);

  const [errorMessage, setErrorMessage] = useState('');

  const handlePayment = async (
    paymentRequest: PayuPaymentRequest,
    digitalSignature: string
  ) => {
    setErrorMessage('');
    try {
      const result = await makePayment(paymentRequest, digitalSignature);
      window.location.href = result.redirectionUrl;
    } catch (error: any) {
      handlePaymentError(error);
    }
  };

  const handlePaymentError = (error: any) => {
    let errorMessage = '';
    if (error?.unrecoverable) {
      errorDispatch(ErrorCondition.Unrecoverable);
      dispatchAuthorizationFailureEvent(CHECKOUT_ELEMENT);
    } else if (error?.statusCode === 409) {
      errorMessage = 'Payment Already Processed';
    } else {
      errorMessage = i18next.t(translationKeys.common.technicalErrorPayment);
    }
    setErrorMessage(errorMessage);
  };

  return (
    <CheckoutLoader
      checkoutForm={
        <CheckoutForm
          handlePayment={handlePayment}
          paymentErrorMessage={
            <PaymentErrorMessage errorMessage={errorMessage} />
          }
        />
      }
      checkoutContinueButton={
        <CheckoutContinueButton
          handlePayment={handlePayment}
          paymentErrorMessage={
            <PaymentErrorMessage errorMessage={errorMessage} />
          }
        />
      }
    />
  );
}
