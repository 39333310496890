import { createRoot, Root } from 'react-dom/client';
import { CustomerAuthTokenType } from '@fpc/reactutils/accountContextProvider';
import {
  ACH_NO_PAY,
  AZURE_AD_TOKEN,
  BFF_BASE_URL,
  CUSTOMER_AUTH_TOKEN,
  CUSTOMER_AUTH_TOKEN_TYPE,
  IS_PRE_AUTH,
  LANGUAGE,
  MERCHANT_CURRENCY,
  NO_CREDIT_CARDS,
  OPTIONAL_TERMS,
  PAYMENT_METHOD_DISPLAY_ORDER,
  PAYMENT_OBJECT_TYPE,
  REDIRECT_STATUS,
  translationKeys
} from '@fpc/common';
import { Language, supportedLanguages } from '@fpc/common/Language';
import {
  PAYMENT_INFO_TOKEN,
  REDIRECT_URL
} from '@fpc/reactutils/checkoutContextProvider';
import i18n from '@fpc/common/i18n';
import { CheckoutWrapper } from './CheckoutWrapper';
import { PaymentObjectType } from '@fpc/common/transactionInterfaces';
import { TraceIdProvider } from '@fpc/reactutils/TraceIdContext';
import { OptionalTermsContext } from '@fpc/reactutils/optionalTermsContextProvider';

export const CheckoutComponentAttributes = [
  AZURE_AD_TOKEN,
  PAYMENT_INFO_TOKEN,
  PAYMENT_OBJECT_TYPE,
  REDIRECT_URL,
  CUSTOMER_AUTH_TOKEN,
  CUSTOMER_AUTH_TOKEN_TYPE,
  IS_PRE_AUTH,
  NO_CREDIT_CARDS,
  LANGUAGE,
  MERCHANT_CURRENCY,
  BFF_BASE_URL,
  REDIRECT_STATUS,
  PAYMENT_METHOD_DISPLAY_ORDER,
  ACH_NO_PAY,
  OPTIONAL_TERMS
] as const;
export type CheckoutComponentAttributeTypes =
  typeof CheckoutComponentAttributes[number];

export class CheckoutWebComponent extends HTMLElement {
  mountPoint: Element | DocumentFragment | undefined;
  bearerToken: string | null;
  paymentInfoToken: string | null;
  paymentObjectType: PaymentObjectType;
  redirectUrl: string | null;
  customerAuthToken: string | null;
  customerAuthTokenType: CustomerAuthTokenType | null;
  isPreAuth: boolean;
  noCreditCards: boolean;
  root: Root | undefined;
  language: Language | null;
  merchantCurrency: string | null;
  bffBaseUrl: string | null;
  redirectStatus: string | null;
  paymentMethodDisplayOrder: string | null;
  achNoPay: boolean | undefined;
  optionalTerms: string | null;

  constructor() {
    super();
    this.paymentInfoToken = this.getAttribute(PAYMENT_INFO_TOKEN);
    this.redirectUrl = this.getAttribute(REDIRECT_URL);
    this.bearerToken = this.getAttribute(AZURE_AD_TOKEN);
    this.customerAuthToken = this.getAttribute(CUSTOMER_AUTH_TOKEN);
    this.customerAuthTokenType = (
      this.getAttribute(CUSTOMER_AUTH_TOKEN_TYPE) ?? 'CAT'
    ).toUpperCase() as CustomerAuthTokenType;
    this.isPreAuth = this.hasAttribute(IS_PRE_AUTH);
    this.noCreditCards = this.hasAttribute(NO_CREDIT_CARDS);
    this.language = (this.getAttribute(LANGUAGE) as Language) ?? null;
    this.merchantCurrency = this.getAttribute(MERCHANT_CURRENCY) ?? null;
    this.bffBaseUrl = this.getAttribute(BFF_BASE_URL) ?? null;
    this.paymentObjectType = (
      this.getAttribute(PAYMENT_OBJECT_TYPE) ?? 'JWT'
    ).toUpperCase() as PaymentObjectType;
    this.redirectStatus = this.getAttribute(REDIRECT_STATUS);
    this.paymentMethodDisplayOrder =
      this.getAttribute(PAYMENT_METHOD_DISPLAY_ORDER) ?? null;
    this.achNoPay = this.hasAttribute(ACH_NO_PAY);
    this.optionalTerms = this.getAttribute(OPTIONAL_TERMS);
  }

  static get observedAttributes() {
    return CheckoutComponentAttributes;
  }

  connectedCallback() {
    this.mountComponent();
  }

  attributeChangedCallback(
    name: CheckoutComponentAttributeTypes,
    oldValue: string,
    newValue: string
  ) {
    if (oldValue !== newValue) {
      switch (name) {
        case AZURE_AD_TOKEN:
          this.bearerToken = newValue;
          break;
        case PAYMENT_INFO_TOKEN:
          this.paymentInfoToken = newValue;
          break;
        case PAYMENT_OBJECT_TYPE:
          if (newValue) {
            this.paymentObjectType =
              newValue.toUpperCase() as PaymentObjectType;
          }
          break;
        case REDIRECT_URL:
          this.redirectUrl = newValue;
          break;
        case CUSTOMER_AUTH_TOKEN:
          this.customerAuthToken = newValue;
          break;
        case CUSTOMER_AUTH_TOKEN_TYPE:
          if (newValue) {
            this.customerAuthTokenType =
              newValue.toUpperCase() as CustomerAuthTokenType;
          }
          break;
        case IS_PRE_AUTH:
          this.isPreAuth = this.hasAttribute(IS_PRE_AUTH);
          break;
        case NO_CREDIT_CARDS:
          this.noCreditCards = this.hasAttribute(NO_CREDIT_CARDS);
          break;
        case LANGUAGE:
          if (newValue) {
            this.language = newValue as Language;
          } else {
            this.language = null;
          }
          break;
        case MERCHANT_CURRENCY:
          this.merchantCurrency = newValue;
          break;
        case BFF_BASE_URL:
          this.bffBaseUrl = newValue;
          break;
        case REDIRECT_STATUS:
          this.redirectStatus = newValue;
          break;
        case PAYMENT_METHOD_DISPLAY_ORDER:
          this.paymentMethodDisplayOrder = newValue;
          break;
        case ACH_NO_PAY:
          this.achNoPay = this.hasAttribute(ACH_NO_PAY);
          break;
        case OPTIONAL_TERMS:
          this.optionalTerms = newValue;
          break;
      }
      this.mountComponent();
    }
  }

  disconnectedCallback() {
    this.root?.render(null);
  }

  mountComponent() {
    if (
      this.language &&
      this.language !== i18n.language &&
      supportedLanguages.includes(this.language)
    ) {
      i18n.changeLanguage(this.language);
    }

    if (!this.mountPoint) {
      this.mountPoint = document.createElement('section');
      this.mountPoint.setAttribute(
        'aria-label',
        i18n.t<string>(translationKeys.checkout.checkout)
      );
      this.mountPoint.setAttribute('lang', i18n.language);
      this.appendChild(this.mountPoint);
    } else if (this.mountPoint && 'setAttribute' in this.mountPoint) {
      this.mountPoint.setAttribute('lang', i18n.language);
    }

    if (!this.root) {
      this.root = createRoot(this.mountPoint);
    }

    const checkoutWrapperProps = {
      bearerToken: this.bearerToken,
      paymentInfoToken: this.paymentInfoToken,
      paymentObjectType: this.paymentObjectType,
      customerAuthToken: this.customerAuthToken,
      customerAuthTokenType: this.customerAuthTokenType,
      redirectUrl: this.redirectUrl,
      isPreAuth: this.isPreAuth,
      noCreditCards: this.noCreditCards,
      merchantCurrency: this.merchantCurrency,
      bffBaseUrl: this.bffBaseUrl,
      language: this.language,
      redirectStatus: this.redirectStatus,
      paymentMethodDisplayOrder: this.paymentMethodDisplayOrder,
      achNoPay: this.achNoPay
    };

    this.root.render(
      <TraceIdProvider>
        {this.optionalTerms !== null ? (
          <OptionalTermsContext.Provider value={this.optionalTerms}>
            <CheckoutWrapper {...checkoutWrapperProps} />
          </OptionalTermsContext.Provider>
        ) : (
          <CheckoutWrapper {...checkoutWrapperProps} />
        )}
      </TraceIdProvider>
    );
  }
}
