import { enUS, TRANSLATION_MISSING } from './en-US';

export const enIE: typeof enUS = {
  common: {
    default: 'default',
    endingIn: 'ending in',
    close: 'Close',
    makeDefaultCard: 'Make default card',
    makeDefaultInfoHover:
      "This will automatically become your default card, because it's the first card in your wallet.",
    processing: 'Processing ...',
    saveCardCheck: 'Save card for future transactions',
    saveCardSuccess: 'Card Added to Ford.com Wallet.',
    technicalErrorPayment:
      'Sorry! We experienced a technical problem, and your payment did not process. Please try again.',
    timeoutSorry: 'Sorry ...',
    timeoutError: "We're experiencing technical difficulties right now.",
    timeoutTryAgain: 'Try Again',
    savePaymentMethodCheck: 'Save payment method for future transactions',
    makeDefaultPaymentMethod: 'Make default payment method',
    savePaymentMethodButton: 'Save Payment Method',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'This will automatically become your default payment method, because it is the first payment method in your wallet.',
    savePaymentMethodSuccess: 'Payment Method Added to Wallet.'
  },
  checkout: {
    payButton: 'Pay {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Sorry! We experienced a technical problem, and your payment did not process. Please refresh the page and try again.',
    technicalErrorPaymentDetachFail:
      'Sorry! We experienced a technical problem, and your payment did not process. Your card was saved to your wallet. Please refresh the page and try again.',
    tosTextBefore: "By clicking the 'Pay' button below, you agree to the ",
    tosLinkText: 'Payment Terms and Conditions and Privacy Policy',
    tosTextAfter: '.',
    useNewCard: 'Use New Payment Method',
    useNewCardDropdownText: 'Use new payment method',
    useNewCardTextBefore: 'Check out with saved card or ',
    useNewCardLinkText: 'use new payment method',
    useNewCardTextAfter: '.',
    cardSelect: 'Credit Card Select',
    checkoutFaster: 'Check out faster with FordPay.',
    checkout: 'Checkout',
    nameOnCard: 'Name on Card',
    fullName: 'Full Name',
    cardNumber: 'Card Number',
    expirationDate: 'Exp. Date',
    securityCode: 'Security Code',
    invalidCCNMessage: 'Your card number is not valid.',
    invalidCVVMessage: 'Your security code is not valid.',
    invalidExpDateMessage: 'Your expiration date is not valid.',
    emptyCCNMessage: 'Your card number is empty.',
    emptyCVVMessage: 'Your security code is empty.',
    emptyExpDateMessage: 'Your expiration date is empty.',
    unsupportedCardMessage:
      'Your card number is not supported. Please enter VISA, MasterCard or AMEX.',
    paymentNotProcessed: 'Your payment could not be processed',
    redirectStatusFailure:
      'Your payment could not be processed. Please try again',
    continue: 'Continue {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      "By clicking the 'Continue' button below, you agree to the ",
    authCheckoutStatement:
      'Check out with saved card, use new payment method, or use Pay By Bank.',
    useNewCardTextBeforePayByBank: 'Check out with saved card, ',
    usePayByBank: ', or use ',
    payByBankLinkText: 'Pay By Bank',
    payByBankHeader: 'Use Pay By Bank',
    use: 'Use ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Use new payment method',
    useNewPaymentDropdownText: 'use new payment method',
    useNewPaymentTextBefore: 'Check out with saved payment method or ',
    useNewPaymentLinkText: 'use new payment method',
    useNewPaymentTextBeforePayByBank: 'Check out with saved payment method, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      "You don't currently have any payment methods in your wallet. Get started by adding the first payment method to your wallet.",
    addPaymentMethod: 'Add Payment Method',
    makeDefault: 'Make Default',
    changeDefaultPMSuccess: 'Your default payment method has changed.',
    makeDefaultSuccessPM: 'Payment method added as default.',
    makeDefaultErrorPM: 'Error setting payment method as default',
    removePMSuccess: 'Payment Method Removed from Ford.com Wallet',
    removePMError: 'Error removing payment method.',
    removePM: 'Remove Payment Method',
    removePMQuestion: 'Remove Payment Method?',
    removePMText:
      'Are you sure you want to remove payment method {{cardNumber}} from your Wallet? All active subscriptions associated with this payment method will fail if the payment method is removed.',
    defaultCard: 'Default Card',
    defaultPM: 'Default Payment Method',
    defaultCardQuestion: 'Default Card?',
    defaultPMQuestion: 'Default Payment Method?',
    defaultCardText:
      'Are you sure you want to change your default card to {{cardNumber}}?',
    defaultPMText:
      'Are you sure you want to change your default payment method to {{cardNumber}}?',
    navigationLabel: 'Account Management Navigation',
    removeDefaultPMSuccess:
      'Default Payment Method Removed from Ford.com Wallet',
    removeDefaultCardError: 'Error removing default card.',
    removeDefaultPMError: 'Error removing default payment method.',
    removeDefaultCard: 'Remove your Default Card?',
    removeDefaultPM: 'Remove your Default Payment Method?',
    removeDefaultPMText:
      'Payment Method {{cardNumber}} is your default payment method. Please pick a new default payment method below. All active subscriptions associated with this payment method will fail if the payment method is removed.',
    removeDefaultPMButton: 'Remove Payment Method & Update Default',
    removeDefaultSelectPM: 'Select new default payment method',
    title: 'Wallet',
    expiration: 'Exp {{date}}',
    expires: 'Expires {{date}}',
    stripeDefaultError: 'There was a technical problem.  Please try again.',
    paymentMethod: 'Payment Method',
    tosTextBefore:
      "By clicking the 'Save Card' button below, you agree to the ",
    tosTextBeforePM:
      "By clicking the 'Save Payment Method' button below, you agree to the",
    tosLinkText: 'Payment Terms and Conditions and Privacy Policy',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'All',
    amount: 'Amount',
    date: 'Date',
    description: 'Description',
    displayingAll: 'Displaying all transactions.',
    displayingDays: 'Displaying transactions from last {{numberOfDays}} days.',
    displayingYear: 'Displaying transactions from {{year}}.',
    filter: 'Last {{numberOfDays}} days',
    loadAll: 'Load All',
    loadMore: 'Load {{x}} more',
    noTransactions: 'No transactions to display.',
    paymentMethod: 'Payment Method',
    refund: 'Refund',
    searchBar: 'Search by description',
    timeFilter: 'Select timeframe for transaction history',
    title: 'Transactions',
    pending: 'Pending'
  },
  mit: {
    confirmPurchase: 'Confirm purchase',
    paymentSuccessful: 'Payment Successful',
    saveCardAlert:
      'To use this card for future payments, it must be saved to your Ford.com wallet.',
    title: 'Payment Method Selection',
    tosTextBefore:
      'By clicking the {{paymentButtonText}} button below, you agree to the ',
    tosLinkText: 'Payment Terms and Conditions and Privacy Policy',
    tosTextAfter: '.',
    useNewCard: 'Use New Card',
    useNewCardDropDownItem: 'Use new card',
    useNewCardTextBefore: 'Select saved card or ',
    useNewCardLinkText: 'use new card',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Use new payment method',
    useNewPaymentDropdownText: 'use new payment method',
    useNewPaymentTextBefore: 'Check out with saved payment method or ',
    useNewPaymentLinkText: 'use new payment method',
    successful: 'Successful',
    savePaymentMethodAlert:
      'To use this payment method for future payments, it must be saved to your wallet.'
  }
};
