import { isNotProd } from '../flags';

export type DispatchEventTarget =
  | 'fordpay-wallet-transaction-history'
  | 'fordpay-checkout'
  | 'fordpay-wallet'
  | 'fordpay-transaction-history'
  | 'fordpay-merchant-initiated-transaction';

export interface NewPaymentMethodErrorEventData {
  type?: string;
  code?: string;
  declineCode?: string;
  docUrl?: string;
  message?: string;
  paymentMethodId?: string | null;
  setupIntentId?: string | null;
  requestLogUrl?: string;
  shouldRetry?: boolean;
}

export interface PaymentMethodDetail {
  type: string;
  card?: {
    brand: string;
    expiryMonth: string;
    expiryYear: string;
    lastFourDigits: string;
    walletType?: string;
  };
  usBankAccount?: {
    bankName: string;
    lastFourDigits: string;
  };
  sepaDebit?: {
    bankCode: string;
    lastFourDigits: string;
  };
  acssDebit?: {
    bankName: string;
    lastFourDigits: string;
  };
}

export interface StripeErrorResponse {
  description?: string;
  errorCode?: string;
  stripeErrorCode?: string;
  declineCode?: string;
  pspErrorCode?: string;
}

export const dispatchAuthorizationFailureEvent = (
  eventTarget: DispatchEventTarget
) => {
  const event = new CustomEvent('fordpay-ui-authorization-error');
  const fpuiComponent = document.querySelector(eventTarget);
  if (fpuiComponent) {
    if (isNotProd()) {
      console.log('dispatching fordpay-ui-authorization-error event');
    }
    fpuiComponent.dispatchEvent(event);
  }
};

export interface DispatchPaymentStatusEventRequest {
  eventTarget: DispatchEventTarget;
  status: 'INCOMPLETE' | 'SUCCESS';
  paymentIntentId: string | null;
  setupIntentId: string | null;
  paymentMethodId: string | null;
  paymentMethodDetails: PaymentMethodDetail | null;
  message?: string;
  declineCode?: string;
  code?: string;
}
export const dispatchPaymentStatusEvent = (
  request: DispatchPaymentStatusEventRequest
) => {
  const event = new CustomEvent('fordpay-ui-payment-status', {
    detail: {
      status: request.status,
      paymentIntentId: request.paymentIntentId,
      setupIntentId: request.setupIntentId,
      paymentMethodId: request.paymentMethodId,
      ...(request.message && { message: request.message }),
      ...(request.declineCode && { declineCode: request.declineCode }),
      ...(request.code && { code: request.code }),
      ...(request.paymentMethodDetails && {
        paymentMethodDetails: request.paymentMethodDetails
      })
    }
  });
  const fpuiComponent = document.querySelector(request.eventTarget);
  if (fpuiComponent) {
    if (isNotProd()) {
      console.log('dispatching fordpay-ui-payment-status event');
    }
    fpuiComponent.dispatchEvent(event);
  }
};

export const dispatchMandateSetupEvent = (
  eventTarget: DispatchEventTarget,
  setupIntentId: string,
  paymentMethodDetails: PaymentMethodDetail | null
) => {
  const event = new CustomEvent('fordpay-ui-mandate-setup', {
    detail: {
      setupIntentId,
      ...(paymentMethodDetails && { paymentMethodDetails })
    }
  });
  const fpuiComponent = document.querySelector(eventTarget);
  if (fpuiComponent) {
    if (isNotProd()) {
      console.log('dispatching fordpay-ui-mandate-setup event');
    }
    fpuiComponent.dispatchEvent(event);
  }
};

export const dispatchSetupIntentEventForAchGuestPay = (
  eventTarget: DispatchEventTarget,
  setupIntentId?: string | undefined,
  error?: StripeErrorResponse | undefined
) => {
  let event: CustomEvent;
  event = new CustomEvent('fordpay-ui-setup-intent', {
    detail: {
      ...(setupIntentId && { setupIntentId }),
      ...(error?.description && { description: error.description }),
      ...(error?.errorCode && { errorCode: error.errorCode }),
      ...(error?.stripeErrorCode && { stripeErrorCode: error.stripeErrorCode }),
      ...(error?.declineCode && { declineCode: error.declineCode }),
      ...(error?.pspErrorCode && { pspErrorCode: error.pspErrorCode })
    }
  });
  const fpuiComponent = document.querySelector(eventTarget);
  if (fpuiComponent) {
    if (isNotProd()) {
      console.log('dispatching fordpay-ui-setup-intent event');
    }
    fpuiComponent.dispatchEvent(event);
  }
};

export const dispatchLoadingEventForAchGuestPay = (
  eventTarget: DispatchEventTarget,
  loadSpinner: boolean
) => {
  const event = new CustomEvent('fordpay-ui-setup-intent-loading', {
    detail: {
      loadSpinner: loadSpinner
    }
  });
  const fpuiComponent = document.querySelector(eventTarget);
  if (fpuiComponent) {
    if (isNotProd()) {
      console.log('dispatching fordpay-ui-setup-intent-loading event');
    }
    fpuiComponent.dispatchEvent(event);
  }
};

export const dispatchNewPaymentMethodErrorEvent = (
  eventTarget: DispatchEventTarget,
  data?: NewPaymentMethodErrorEventData
) => {
  const event = new CustomEvent('fordpay-new-payment-method-error', {
    detail: data
  });
  const fpuiComponent = document.querySelector(eventTarget);
  if (fpuiComponent) {
    if (isNotProd()) {
      console.log('dispatching fordpay-new-payment-method-error event');
    }
    fpuiComponent.dispatchEvent(event);
  }
};

export const dispatchCheckoutFormOnloadEvent = (
  eventTarget: DispatchEventTarget,
  description: object
) => {
  const onLoadEvent = new CustomEvent('fordpay-checkoutform-onload', {
    detail: description
  });
  const fpuiComp = document.querySelector(eventTarget);
  if (fpuiComp) {
    console.log('fordpay-checkoutform-onload', description);
    fpuiComp.dispatchEvent(onLoadEvent);
  }
};
