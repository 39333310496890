import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  areFieldsValid,
  changeImpactedElement,
  createHostedPaymentFields,
  loadBluesnapScript,
  removeBluesnapScript
} from './Bluesnap';
import { BluesnapWalletContainer } from '@fpc/common/components/BluesnapComponents';
import { TermsAndConditions } from '@fpc/common/components/TermsAndConditions';
import { Button, buttonPayNow, Currency, translationKeys } from '@fpc/common';
import { BluesnapCheckoutContext } from '@fpc/reactutils/checkoutContextProvider';
import { BluesnapSupportedCards } from './BluesnapSupportedCards';
import i18n from '@fpc/common/i18n';
import { Style } from '@fpc/common/Styles';
import {
  CheckoutInput,
  InputGridArea,
  InputLabel
} from '@fpc/common/components/InputComponents';

declare let bluesnap: any;

export interface BlueSnapPaymentFormProps {
  bluesnapPaymentToken: string;
  onSubmitPayment: () => void;
  bluesnapThreeDS: any;
  guestCheckout: boolean;
  fordCustomerId?: string;
  bluesnapCustomerId?: number;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  errorMessage: string;
  isSubmitDisabled: boolean;
  setSubmitDisabled: (isSubmitDisabled: boolean) => void;
}

export interface TransactionDetail {
  currency: Currency;
  amount: number;
}

export function BlueSnapPaymentForm(props: BlueSnapPaymentFormProps) {
  const { tokens, redirectUrl, merchantCountry, transaction, bffBaseUrl } =
    useContext(BluesnapCheckoutContext);
  const [bluesnapScriptLoaded, setBluesnapScriptLoaded] = useState(false);
  const {
    bluesnapPaymentToken,
    onSubmitPayment,
    bluesnapThreeDS,
    setErrorMessage,
    errorMessage,
    isSubmitDisabled,
    setSubmitDisabled
  } = props;
  const [cardType, setCardType] = useState<string | null>(null);

  useEffect(() => {
    loadBluesnapScript(() => {
      setBluesnapScriptLoaded(true);
      setErrorMessage('');
    });

    return () => {
      removeBluesnapScript();
    };
  }, []);

  useEffect(() => {
    if (
      bluesnapPaymentToken == null ||
      bluesnapPaymentToken === '' ||
      !bluesnapScriptLoaded
    ) {
      return;
    } else {
      createHostedPaymentFields(
        bluesnap,
        bluesnapPaymentToken,
        tokens.bearerToken!,
        tokens.paymentInfoToken,
        transaction?.currency.toString()!,
        transaction?.amount / 100,
        merchantCountry,
        props.guestCheckout,
        redirectUrl,
        setCardType,
        props.fordCustomerId,
        props.bluesnapCustomerId,
        bffBaseUrl ? bffBaseUrl : '',
        tokens.digitalSignature
      );
    }
  }, [bluesnapScriptLoaded, bluesnapPaymentToken]);

  function handleSubmit(event: any) {
    event.preventDefault();
    setTimeout(() => {
      if (bluesnapPaymentToken && areFieldsValid()) {
        bluesnap.hostedPaymentFieldsSubmitData(function (callback: any) {
          setSubmitDisabled(true);
          if (callback.cardData) {
            onSubmitPayment();
          } else {
            setSubmitDisabled(false);
            for (const i in callback.error) {
              changeImpactedElement(
                callback.error[i].tagId,
                'hosted-field-valid hosted-field-focus',
                'hosted-field-invalid'
              );
              const element = document.getElementById(
                callback.error[i].tagId + '-help'
              );
              // @ts-ignore
              element.classList.remove('helper-text-green');
              // @ts-ignore
              element.textContent = callback.error[i].errorDescription;
            }
            setErrorMessage(callback.error[0].errorDescription);
          }
        }, bluesnapThreeDS);
      }
    }, 0);
  }

  return (
    <div data-testid="bluesnap-payment-form-container">
      <BluesnapWalletContainer data-bluesnap="walletButton" />
      <div className="rectangle" />
      <form className="panel-body" id="checkout-form" action="#">
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '10px',
            gridAutoRows: 'minmax(50px, auto)'
          }}
        >
          <InputGridArea style={{ gridArea: '1 / 1 / auto / -1' }}>
            <InputLabel htmlFor="card-number">
              {i18n.t<string>(translationKeys.checkout.cardNumber)}
            </InputLabel>
            <div
              className="input-group"
              style={{ display: '', position: 'relative' }}
            >
              <CheckoutInput
                className="form-control"
                id="card-number"
                data-bluesnap="ccn"
                style={{
                  borderBottomLeftRadius: '.25rem',
                  borderTopLeftRadius: '.25rem',
                  borderBottomRightRadius: '.25rem',
                  borderTopRightRadius: '.25rem'
                }}
              />
              <BluesnapSupportedCards cardType={cardType} />
            </div>
            <span className="helper-text" id="ccn-help" />
          </InputGridArea>
          <InputGridArea style={{ gridArea: '2 / 1 / auto / 1' }}>
            <InputLabel htmlFor="exp-date">
              {i18n.t<string>(translationKeys.checkout.expirationDate)}
            </InputLabel>
            <CheckoutInput
              className="form-control bluesnap-checkout-input-border"
              id="exp-date"
              data-bluesnap="exp"
            />
            <span className="helper-text" id="exp-help" />
          </InputGridArea>
          <InputGridArea style={{ gridArea: '2 / 2 / auto / 2' }}>
            <InputLabel htmlFor="cvv">
              {i18n.t<string>(translationKeys.checkout.securityCode)}
            </InputLabel>
            <CheckoutInput
              className="form-control bluesnap-checkout-input-border"
              id="cvv"
              data-bluesnap="cvv"
            />
            <span className="helper-text" id="cvv-help" />
          </InputGridArea>
        </div>
        {transaction && (
          <>
            <TermsAndConditions translationGroup={translationKeys.checkout} />
            <div
              role={'alert'}
              style={{ color: Style.color.error, paddingTop: '0.5em' }}
            >
              {errorMessage}
            </div>
            <div style={{ gridArea: '3 / 1 / auto / -1' }}>
              <Button
                id="submit"
                data-testid={'bluesnap-form-pay-button'}
                onClick={handleSubmit}
                style={{ marginTop: '1em' }}
                disabled={isSubmitDisabled}
              >
                {buttonPayNow(transaction.amount, transaction.currency)}
              </Button>
            </div>
          </>
        )}
      </form>
    </div>
  );
}
