import { enUS, TRANSLATION_MISSING } from './en-US';

export const huHU: typeof enUS = {
  common: {
    default: 'alap\u00e9rtelmezett',
    endingIn: 'v\u00e9gz\u0151d\u00e9se:',
    close: 'Bez\u00e1r\u00e1s',
    makeDefaultCard:
      'Be\u00e1ll\u00edt\u00e1s alap\u00e9rtelmezett k\u00e1rtyak\u00e9nt',
    makeDefaultInfoHover:
      'Ez automatikusan az alap\u00e9rtelmezett k\u00e1rty\u00e1v\u00e1 v\u00e1lik, mert ez az els\u0151 k\u00e1rtya a t\u00e1rc\u00e1j\u00e1ban.',
    processing: 'Feldolgoz\u00e1s alatt...',
    saveCardCheck:
      'K\u00e1rtya ment\u00e9se a j\u00f6v\u0151beni tranzakci\u00f3khoz',
    saveCardSuccess: 'A k\u00e1rtya a Ford.com t\u00e1rc\u00e1ba ker\u00fclt.',
    technicalErrorPayment:
      'Sajn\u00e1ljuk! Egy technikai probl\u00e9ma miatt a fizet\u00e9s nem t\u00f6rt\u00e9nt meg. K\u00e9rj\u00fck, pr\u00f3b\u00e1lja \u00fajra!',
    timeoutSorry: 'Eln\u00e9z\u00e9st k\u00e9r\u00fcnk...',
    timeoutError:
      'Jelenleg technikai neh\u00e9zs\u00e9gekkel k\u00fczd\u00fcnk.',
    timeoutTryAgain: 'Pr\u00f3b\u00e1lja \u00fajra',
    savePaymentMethodCheck:
      'Fizet\u00e9si m\u00f3d ment\u00e9se a j\u00f6v\u0151beni tranzakci\u00f3khoz',
    makeDefaultPaymentMethod:
      'Be\u00e1ll\u00edt\u00e1s alap\u00e9rtelmezett fizet\u00e9si m\u00f3dk\u00e9nt',
    savePaymentMethodButton: 'Fizet\u00e9si m\u00f3d ment\u00e9se',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Ez lesz automatikusan az alap\u00e9rtelmezett fizet\u00e9si m\u00f3d, mert ez az els\u0151 fizet\u00e9si m\u00f3d a p\u00e9nzt\u00e1rc\u00e1j\u00e1ban.',
    savePaymentMethodSuccess:
      'A fizet\u00e9si m\u00f3d hozz\u00e1adva a Wallethez.'
  },
  checkout: {
    payButton: '{{formattedCurrency}} kifizet\u00e9se',
    technicalErrorPaymentRefresh:
      'Sajn\u00e1ljuk! Egy technikai probl\u00e9ma miatt a fizet\u00e9s nem t\u00f6rt\u00e9nt meg. K\u00e9rj\u00fck, friss\u00edtse az oldalt, \u00e9s pr\u00f3b\u00e1lja \u00fajra!\u00a0',
    technicalErrorPaymentDetachFail:
      'Sajn\u00e1ljuk! Egy technikai probl\u00e9ma miatt a fizet\u00e9s nem t\u00f6rt\u00e9nt meg. A k\u00e1rty\u00e1j\u00e1t elmentett\u00fck a t\u00e1rc\u00e1j\u00e1ba. K\u00e9rj\u00fck, pr\u00f3b\u00e1lja \u00fajra!',
    tosTextBefore:
      'Az al\u00e1bbi \u201eFizet\u00e9s\u201d gombra kattintva \u00d6n elfogadja ',
    tosLinkText:
      'fizet\u00e9si felt\u00e9telek \u00e9s adatv\u00e9delmi szab\u00e1lyzat',
    tosTextAfter: '.',
    useNewCard: '\u00daj k\u00e1rtya haszn\u00e1lata',
    useNewCardDropdownText: '\u00daj k\u00e1rtya hozz\u00e1ad\u00e1sa',
    useNewCardTextBefore: 'Fizet\u00e9s mentett k\u00e1rty\u00e1val vagy ',
    useNewCardLinkText: '\u00faj fizet\u00e9si m\u00f3d haszn\u00e1lata',
    useNewCardTextAfter: '.',
    cardSelect: 'Hitelk\u00e1rtya kiv\u00e1laszt\u00e1sa',
    checkoutFaster:
      'A FordPay seg\u00edts\u00e9g\u00e9vel gyorsabban fizethet.',
    checkout: 'Fizet\u00e9s',
    nameOnCard: 'A k\u00e1rty\u00e1n szerepl\u0151 n\u00e9v',
    fullName: 'Teljes n\u00e9v',
    cardNumber: 'K\u00e1rtyasz\u00e1m',
    expirationDate: 'Lej\u00e1rat D\u00e1tum',
    securityCode: 'Biztons\u00e1gi k\u00f3d',
    invalidCCNMessage:
      'A megadott k\u00e1rtyasz\u00e1m nem \u00e9rv\u00e9nyes.',
    invalidCVVMessage:
      'A megadott biztons\u00e1gi k\u00f3d nem \u00e9rv\u00e9nyes.',
    invalidExpDateMessage:
      'A megadott lej\u00e1rati d\u00e1tum nem \u00e9rv\u00e9nyes.',
    emptyCCNMessage: 'Nem adott meg k\u00e1rtyasz\u00e1mot.',
    emptyCVVMessage: 'Nem adott meg biztons\u00e1gi k\u00f3dot.',
    emptyExpDateMessage: 'Nem adott meg lej\u00e1rati d\u00e1tumot.',
    unsupportedCardMessage:
      'A megadott k\u00e1rtyasz\u00e1m nem t\u00e1mogatott. K\u00e9rj\u00fck, VISA, MasterCard vagy AMEX k\u00e1rtyasz\u00e1mot adjon meg.',
    paymentNotProcessed: 'A fizet\u00e9st nem siker\u00fclt feldolgozni',
    redirectStatusFailure:
      'A fizet\u00e9st nem siker\u00fclt feldolgozni. K\u00e9rj\u00fck, pr\u00f3b\u00e1lja \u00fajra!\u00a0',
    continue: '{{formattedCurrency}} Folytatni ',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'Az al\u00e1bbi \u201eFolytatni \u201d gombra kattintva \u00d6n elfogadja ',
    authCheckoutStatement:
      'Fizessen el mentett k\u00e1rty\u00e1val, haszn\u00e1ljon \u00faj fizet\u00e9si m\u00f3dot, vagy haszn\u00e1lja a banki fizet\u00e9st.',
    useNewCardTextBeforePayByBank: 'Fizessen el mentett k\u00e1rty\u00e1val, ',
    usePayByBank: ', vagy haszn\u00e1lja ',
    payByBankLinkText: 'a banki fizet\u00e9st',
    payByBankHeader: 'Haszn\u00e1lja a banki fizet\u00e9st.',
    use: 'Haszn\u00e1lat ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Uj fizet\u00e9si m\u00f3d haszn\u00e1lata',
    useNewPaymentDropdownText: '\u00faj fizet\u00e9si m\u00f3d haszn\u00e1lata',
    useNewPaymentTextBefore:
      'N\u00e9zze meg a mentett fizet\u00e9si m\u00f3ddal ill ',
    useNewPaymentLinkText: '\u00faj fizet\u00e9si m\u00f3d haszn\u00e1lata',
    useNewPaymentTextBeforePayByBank:
      'N\u00e9zze meg a mentett fizet\u00e9si m\u00f3ddal, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'Jelenleg nincs fizet\u00e9si m\u00f3dja a p\u00e9nzt\u00e1rc\u00e1j\u00e1ban. Kezdje el az els\u0151 fizet\u00e9si m\u00f3d hozz\u00e1ad\u00e1s\u00e1t a p\u00e9nzt\u00e1rc\u00e1j\u00e1hoz.',
    addPaymentMethod: 'Fizet\u00e9si m\u00f3d hozz\u00e1ad\u00e1sa',
    makeDefault: 'Be\u00e1ll\u00edt\u00e1s alap\u00e9rtelmezettk\u00e9nt',
    changeDefaultPMSuccess:
      'Votre mode de paiement par d\u00e9faut a \u00e9t\u00e9 modifi\u00e9.',
    makeDefaultSuccessPM:
      'Fizet\u00e9si m\u00f3d hozz\u00e1adva alap\u00e9rtelmezettnek.',
    makeDefaultErrorPM:
      'Hiba az alap\u00e9rtelmezett fizet\u00e9si m\u00f3d be\u00e1ll\u00edt\u00e1sakor.',
    removePMSuccess:
      'Fizet\u00e9si m\u00f3d elt\u00e1vol\u00edtva a Ford.com t\u00e1rc\u00e1b\u00f3l.',
    removePMError:
      'Hiba a fizet\u00e9si m\u00f3d elt\u00e1vol\u00edt\u00e1sakor.',
    removePM: 'Hiba a fizet\u00e9si m\u00f3d elt\u00e1vol\u00edt\u00e1sakor.',
    removePMQuestion: 'Fizet\u00e9si m\u00f3d elt\u00e1vol\u00edt\u00e1sa?',
    removePMText:
      'Biztosan el szeretn\u00e9 t\u00e1vol\u00edtani a fizet\u00e9si m\u00f3dot a t\u00e1rc\u00e1j\u00e1b\u00f3l? {{cardNumber}}\u00a0Az ehhez a fizet\u00e9si m\u00f3dhoz t\u00e1rs\u00edtott \u00f6sszes akt\u00edv el\u0151fizet\u00e9s meghi\u00fasul, ha a fizet\u00e9si m\u00f3dot elt\u00e1vol\u00edtj\u00e1k.',
    defaultCard: 'Ment\u00e9s alap\u00e9rtelmezett k\u00e1rtyak\u00e9nt',
    defaultPM: 'Alap\u00e9rtelmezett fizet\u00e9si m\u00f3d',
    defaultCardQuestion:
      'Ment\u00e9s alap\u00e9rtelmezett k\u00e1rtyak\u00e9nt?',
    defaultPMQuestion: 'Alap\u00e9rtelmezett fizet\u00e9si m\u00f3d?',
    defaultCardText:
      'Biztosan m\u00f3dos\u00edtja az alap\u00e9rtelmezett k\u00e1rty\u00e1t erre: {{cardNumber}} ?',
    defaultPMText:
      'Biztosan meg akarja v\u00e1ltoztatni az alap\u00e9rtelmezett fizet\u00e9si m\u00f3dj\u00e1t erre: {{cardNumber}}?\u00a0',
    navigationLabel: 'Fi\u00f3kkezel\u00e9si navig\u00e1ci\u00f3',
    removeDefaultPMSuccess:
      'Az alap\u00e9rtelmezett fizet\u00e9si m\u00f3d elt\u00e1vol\u00edtva a Ford.com t\u00e1rc\u00e1b\u00f3l.',
    removeDefaultCardError:
      'Hiba az alap\u00e9rtelmezett k\u00e1rtya elt\u00e1vol\u00edt\u00e1sakor.',
    removeDefaultPMError:
      'Hiba az alap\u00e9rtelmezett fizet\u00e9si m\u00f3d elt\u00e1vol\u00edt\u00e1sakor.',
    removeDefaultCard:
      'Alap\u00e9rtelmezett k\u00e1rtya elt\u00e1vol\u00edt\u00e1sa',
    removeDefaultPM:
      'Az alap\u00e9rtelmezett fizet\u00e9si m\u00f3d elt\u00e1vol\u00edt\u00e1sa?',
    removeDefaultPMText:
      '{{cardNumber}} a fizet\u00e9si m\u00f3d alap\u00e9rtelmezettje. V\u00e1lasszon \u00faj alap\u00e9rtelmezett fizet\u00e9si m\u00f3dot az al\u00e1bbiak k\u00f6z\u00fcl. Az ehhez a fizet\u00e9si m\u00f3dhoz t\u00e1rs\u00edtott \u00f6sszes akt\u00edv el\u0151fizet\u00e9s meghi\u00fasul, ha a fizet\u00e9si m\u00f3dot elt\u00e1vol\u00edtj\u00e1k.',
    removeDefaultPMButton:
      'Fizet\u00e9si m\u00f3d elt\u00e1vol\u00edt\u00e1sa \u00e9s az alap\u00e9rtelmezett friss\u00edt\u00e9se',
    removeDefaultSelectPM:
      'V\u00e1lasszon \u00faj alap\u00e9rtelmezett fizet\u00e9si m\u00f3dot',
    title: 'T\u00e1rca',
    expiration: 'Lej\u00e1rat\u00a0{{date}}',
    expires: 'Lej\u00e1rat d\u00e1tuma: {{date}}',
    stripeDefaultError:
      'Technikai probl\u00e9ma mer\u00fclt fel. K\u00e9rj\u00fck, pr\u00f3b\u00e1lja \u00fajra!\u00a0',
    paymentMethod: 'Fizet\u00e9si m\u00f3d',
    tosTextBefore:
      'Az al\u00e1bbi \u201eK\u00e1rtya ment\u00e9se\u201d gombra kattintva \u00d6n elfogadja ',
    tosTextBeforePM:
      'Az al\u00e1bbi "Fizet\u00e9si m\u00f3d ment\u00e9se" gombra kattint\u00e1ssal elfogadja a',
    tosLinkText:
      '\nfizet\u00e9si felt\u00e9telek \u00e9s adatv\u00e9delmi szab\u00e1lyzat',
    tosTextAfter: '.'
  },
  transaction: {
    all: '\u00d6sszes',
    amount: '\u00d6sszeg',
    date: 'D\u00e1tum',
    description: 'Le\u00edr\u00e1s',
    displayingAll: 'Az \u00f6sszes tranzakci\u00f3 megjelen\u00edt\u00e9se.',
    displayingDays:
      'Az elm\u00falt {{numberOfDays}} nap tranzakci\u00f3inak megjelen\u00edt\u00e9se.',
    displayingYear:
      '{{year}} \u00e9vi tranzakci\u00f3k megjelen\u00edt\u00e9se.',
    filter: 'Elm\u00falt {{numberOfDays}} nap',
    loadAll: 'Az \u00f6sszes bet\u00f6lt\u00e9se',
    loadMore: 'Tov\u00e1bbi {{x}} bet\u00f6lt\u00e9se',
    noTransactions: 'Nincs megjelen\u00edthet\u0151 tranzakci\u00f3.',
    paymentMethod: 'Fizet\u00e9si m\u00f3d',
    refund: 'Visszat\u00e9r\u00edt\u00e9s',
    searchBar: 'Keres\u00e9s le\u00edr\u00e1s szerint',
    timeFilter:
      'Id\u0151keret kiv\u00e1laszt\u00e1sa a tranzakci\u00f3s el\u0151zm\u00e9nyekhez',
    title: 'Tranzakci\u00f3k',
    pending: 'F\u00fcgg\u0151ben lev\u0151'
  },
  mit: {
    confirmPurchase: 'V\u00e1s\u00e1rl\u00e1s meger\u0151s\u00edt\u00e9se',
    paymentSuccessful: 'Sikeres fizet\u00e9s',
    saveCardAlert:
      'Ha ezt a k\u00e1rty\u00e1t j\u00f6v\u0151beni fizet\u00e9sekhez is szeretn\u00e9 haszn\u00e1lni, mentse el a Ford.com t\u00e1rc\u00e1j\u00e1ba.',
    title: 'Fizet\u00e9si m\u00f3d kiv\u00e1laszt\u00e1sa',
    tosTextBefore:
      'Az al\u00e1bbi {{paymentButtonText}} gombra kattintva \u00d6n elfogadja ',
    tosLinkText:
      'fizet\u00e9si felt\u00e9telek \u00e9s adatv\u00e9delmi szab\u00e1lyzat',
    tosTextAfter: '.',
    useNewCard: '\u00daj k\u00e1rtya haszn\u00e1lata',
    useNewCardDropDownItem: '\u00daj k\u00e1rtya haszn\u00e1lata',
    useNewCardTextBefore:
      'V\u00e1lasszon ki egy elmentett k\u00e1rty\u00e1t vagy ',
    useNewCardLinkText: 'haszn\u00e1ljon egy \u00faj k\u00e1rty\u00e1t',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Uj fizet\u00e9si m\u00f3d haszn\u00e1lata',
    useNewPaymentDropdownText: '\u00faj fizet\u00e9si m\u00f3d haszn\u00e1lata',
    useNewPaymentTextBefore:
      'N\u00e9zze meg a mentett fizet\u00e9si m\u00f3ddal ill ',
    useNewPaymentLinkText: '\u00faj fizet\u00e9si m\u00f3d haszn\u00e1lata',
    successful: 'Sikeres',
    savePaymentMethodAlert:
      'Ha ezt a fizet\u00e9si m\u00f3dot szeretn\u00e9 haszn\u00e1lni a j\u00f6v\u0151beni fizet\u00e9sekhez, el kell mentenie a p\u00e9nzt\u00e1rc\u00e1j\u00e1ba.'
  }
};
