import { enUS, TRANSLATION_MISSING } from './en-US';

export const nlNL: typeof enUS = {
  common: {
    default: 'standaard',
    endingIn: 'eindigend op',
    close: 'Sluiten',
    makeDefaultCard: 'Standaardkaart maken',
    makeDefaultInfoHover:
      'Deze wordt automatisch je standaardkaart omdat het de eerste kaart in je portemonnee is.',
    processing: 'Wordt verwerkt ...',
    saveCardCheck: 'Kaart opslaan voor toekomstige transacties',
    saveCardSuccess: 'Kaart toegevoegd aan de ford.com-portemonnee.',
    technicalErrorPayment:
      'Onze excuses! Er is een technisch probleem opgetreden en je betaling is niet verwerkt. Probeer het opnieuw.',
    timeoutSorry: 'Onze excuses ...',
    timeoutError: 'We hebben momenteel technische problemen.',
    timeoutTryAgain: 'Probeer het opnieuw',
    savePaymentMethodCheck:
      'Betaalmethode opslaan voor toekomstige transacties',
    makeDefaultPaymentMethod: 'Maak standaard betaalmethode',
    savePaymentMethodButton: 'Betaalmethode opslaan',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Dit wordt automatisch uw standaard betaalmethode, omdat het de eerste betaalmethode in uw portemonnee is.',
    savePaymentMethodSuccess: 'Betaalmethode is toegevoegd aan Wallet.'
  },
  checkout: {
    payButton: '{{formattedCurrency}} betalen',
    technicalErrorPaymentRefresh:
      'Onze excuses! We hadden een technisch probleem en je betaling is niet verwerkt. Vernieuw de pagina en probeer het opnieuw.\u00a0',
    technicalErrorPaymentDetachFail:
      'Onze excuses! Er is een technisch probleem opgetreden en je betaling is niet verwerkt. Je kaart is opgeslagen in je portemonnee. Probeer het opnieuw.',
    tosTextBefore:
      "Door hieronder op de knop 'Betalen' te klikken, ga je akkoord met de ",
    tosLinkText: 'betalingsvoorwaarden en de privacyverklaring',
    tosTextAfter: '.',
    useNewCard: 'Nieuwe kaart gebruiken',
    useNewCardDropdownText: 'Nieuwe kaart toevoegen',
    useNewCardTextBefore: 'Afrekenen met een opgeslagen kaart of ',
    useNewCardLinkText: 'nieuwe betaalmethode gebruiken',
    useNewCardTextAfter: '.',
    cardSelect: 'Creditcard selecteren',
    checkoutFaster: 'Betaal sneller met FordPay.',
    checkout: 'Afrekenen',
    nameOnCard: 'Naam op kaart',
    fullName: 'Volledige naam',
    cardNumber: 'Kaartnummer',
    expirationDate: 'Verval Datum',
    securityCode: 'Beveiligingscode',
    invalidCCNMessage: 'Het kaartnummer is niet geldig.',
    invalidCVVMessage: 'De beveiligingscode is niet geldig.',
    invalidExpDateMessage: 'De vervaldatum is niet geldig.',
    emptyCCNMessage: 'Het kaartnummer is leeg.',
    emptyCVVMessage: 'De beveiligingscode is leeg.',
    emptyExpDateMessage: 'De vervaldatum is leeg.',
    unsupportedCardMessage:
      'Het kaartnummer wordt niet ondersteund. Voer VISA, MasterCard of AMEX in.',
    paymentNotProcessed: 'De betaling kon niet worden verwerkt',
    redirectStatusFailure:
      'De betaling kon niet worden verwerkt. Probeer het opnieuw.\u00a0',
    continue: '{{formattedCurrency}} Doorgaan ',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      "Door hieronder op de knop 'Doorgaan ' te klikken, gaat u akkoord met de ",
    authCheckoutStatement:
      'Betaal met uw opgeslagen kaart, gebruik een nieuwe betaalmethode of gebruik Betalen per bank.',
    useNewCardTextBeforePayByBank: 'Betaal met uw opgeslagen kaart, ',
    usePayByBank: ', of gebruik ',
    payByBankLinkText: 'Betalen per bank',
    payByBankHeader: 'Gebruik Betalen per bank.',
    use: 'Gebruik ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Nieuwe betaalmethode gebruiken',
    useNewPaymentDropdownText: 'nieuwe betaalmethode gebruiken',
    useNewPaymentTextBefore: 'Afrekenen met opgeslagen betaalmethode of ',
    useNewPaymentLinkText: 'nieuwe betaalmethode gebruiken',
    useNewPaymentTextBeforePayByBank:
      'Afrekenen met opgeslagen betaalmethode, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'U hebt momenteel geen betaalmethoden in uw portemonnee. Begin door de eerste betaalmethode aan uw portemonnee toe te voegen.',
    addPaymentMethod: 'Betaalmethode toevoegen',
    makeDefault: 'Instellen als standaard',
    changeDefaultPMSuccess: 'Uw standaard betaalmethode is gewijzigd.',
    makeDefaultSuccessPM: 'Betalingsmethode toegevoegd als standaard.',
    makeDefaultErrorPM:
      'Fout bij het instellen van de betalingsmethode als standaard.',
    removePMSuccess: 'Betalingsmethode verwijderd uit Ford.com-portemonnee',
    removePMError: 'Fout bij het verwijderen van de betalingsmethode.',
    removePM: 'Verwijder betalingsmethode',
    removePMQuestion: 'Betalingsmethode verwijderen?\u00a0',
    removePMText:
      'Weet u zeker dat u betalingsmethode {{cardNumber}} uit uw portemonnee wilt verwijderen? Alle actieve abonnementen die aan deze betaalmethode zijn gekoppeld, mislukken als de betaalmethode wordt verwijderd.',
    defaultCard: 'Standaardkaart',
    defaultPM: 'Standaard betalingsmethode\u00a0',
    defaultCardQuestion: 'Standaardkaart?',
    defaultPMQuestion: 'Standaard betalingsmethode?\u00a0',
    defaultCardText:
      'Weet je zeker dat je je standaardkaart wilt wijzigen naar {{cardNumber}}?',
    defaultPMText:
      'Weet u zeker dat u uw standaard betalingsmethode wilt wijzigen naar {{cardNumber}}?',
    navigationLabel: 'Navigatie Accountbeheer',
    removeDefaultPMSuccess:
      'Standaard betalingsmethode verwijderd uit Ford.com-portemonnee',
    removeDefaultCardError: 'Fout bij het verwijderen van de standaardkaart.',
    removeDefaultPMError:
      'Fout bij het verwijderen van de standaard betalingsmethode.',
    removeDefaultCard: 'Je standaardkaart verwijderen',
    removeDefaultPM: 'Verwijder uw standaard betalingsmethode?',
    removeDefaultPMText:
      'Betalingsmethode {{cardNumber}} is uw standaard betalingsmethode. Kies hieronder een nieuwe standaard betalingsmethode. Alle actieve abonnementen die aan deze betaalmethode zijn gekoppeld, mislukken als de betaalmethode wordt verwijderd.',
    removeDefaultPMButton: 'Verwijder betalingsmethode en update standaard',
    removeDefaultSelectPM: 'Selecteer nieuwe standaard betalingsmethode',
    title: 'Portemonnee',
    expiration: 'Vervaldatum\u00a0{{date}}',
    expires: 'Verloopt op {{date}}',
    stripeDefaultError:
      'Er is een technisch probleem opgetreden. Probeer het opnieuw.\u00a0',
    paymentMethod: 'Betaalmethode',
    tosTextBefore:
      "Door hieronder op de knop 'Kaart opslaan' te klikken, ga je akkoord met de ",
    tosTextBeforePM:
      "Door op de knop 'Betalingsmethode opslaan' hieronder te klikken, gaat u akkoord met de",
    tosLinkText: 'betalingsvoorwaarden en de privacyverklaring',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Alles',
    amount: 'Bedrag',
    date: 'Datum',
    description: 'Beschrijving',
    displayingAll: 'Alle transacties worden getoond.',
    displayingDays:
      'Toont transacties van de afgelopen {{numberOfDays}} dagen.',
    displayingYear: 'Toont transacties vanaf {{year}}.',
    filter: 'Afgelopen {{numberOfDays}} dagen',
    loadAll: 'Alles laden',
    loadMore: 'Nog {{x}} laden',
    noTransactions: 'Geen transacties om weer te geven.',
    paymentMethod: 'Betaalmethode',
    refund: 'Terugbetaling',
    searchBar: 'Zoeken op beschrijving',
    timeFilter: 'Tijdsbestek selecteren voor transactiegeschiedenis',
    title: 'Transacties',
    pending: 'In behandeling'
  },
  mit: {
    confirmPurchase: 'Bevestig aankoop',
    paymentSuccessful: 'Betaling gelukt',
    saveCardAlert:
      'Om deze kaart voor toekomstige betalingen te gebruiken, moet je deze in jouw Ford.com-portemonnee opslaan.',
    title: 'Selectie betaalmethode',
    tosTextBefore:
      'Door hieronder op de knop {{paymentButtonText}} te klikken, ga je akkoord met de ',
    tosLinkText: 'betalingsvoorwaarden en de privacyverklaring',
    tosTextAfter: '.',
    useNewCard: 'Nieuwe kaart gebruiken',
    useNewCardDropDownItem: 'Nieuwe kaart gebruiken',
    useNewCardTextBefore: 'Selecteer een opgeslagen kaart of ',
    useNewCardLinkText: 'gebruik een nieuwe kaart',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Nieuwe betaalmethode gebruiken',
    useNewPaymentDropdownText: 'nieuwe betaalmethode gebruiken',
    useNewPaymentTextBefore: 'Afrekenen met opgeslagen betaalmethode of ',
    useNewPaymentLinkText: 'nieuwe betaalmethode gebruiken',
    successful: 'Succesvol',
    savePaymentMethodAlert:
      'Om deze betaalmethode voor toekomstige betalingen te gebruiken, moet deze in uw portemonnee worden opgeslagen.'
  }
};
