import styled from 'styled-components/macro';
import { FordStyles } from './FordStyles';
import { Appearance } from '@stripe/stripe-js';

export const Style = FordStyles;

export const FordPayUIStyle = styled.div`
  && {
    font-family: ${Style.fontFamily};
    text-align: center;
    color: ${Style.color.gray3};
    background-color: ${Style.color.white};
    padding: 0.75em;
    margin-block: 1em;
    font-size: ${Style.baseFontSize};
    font-weight: 100;
    letter-spacing: 0.06em;
  }

  && a {
    color: ${Style.color.primary};
    text-decoration: underline;
  }

  && a:hover {
    color: ${Style.color.secondary};
    text-decoration: underline;
  }

  && a:focus {
    color: ${Style.color.secondary};
    text-decoration: underline;
  }

  && h2 {
    color: ${Style.color.primary};
    margin-block: 1em;
    letter-spacing: 0;
    text-align: left;
    font-weight: normal;
  }

  && h2.new-payment-method {
    font-size: 1.3em;
  }
`;

export const STRIPE_APPEARANCE: Appearance = {
  theme: 'flat',
  variables: {
    colorDanger: Style.color.error,
    colorIconCardError: Style.color.error,
    colorIconCardCvc: '#D8D8D8',
    fontFamily: Style.fontFamily,
    borderRadius: '3px',
    colorBackground: Style.color.white,
    colorText: Style.color.gray3
  },
  rules: {
    '.Label': {
      fontSize: '0.7em',
      letterSpacing: '0.06em',
      lineHeight: '1em'
    },
    '.Input': {
      color: Style.color.primary,
      fontSize: '1em',
      letterSpacing: '0.06em',
      lineHeight: '1.5em',
      border: `1px solid ${Style.color.disabled}`
    },
    '.Input--empty': {
      color: Style.color.gray3
    },
    '.Input--invalid': {
      color: Style.color.primary,
      borderColor: Style.color.error
    },
    '.AccordionItem': {
      color: Style.color.primary
    },
    '.AccordionItem--selected': {
      color: Style.color.primary
    },
    '.PickerItem': {
      border: '1px solid ' + Style.color.gray2
    },
    '.CheckboxInput': {
      border: '1px solid ' + Style.color.gray2
    }
  }
};

export const loadingSpinnerBig = { minHeight: '18.5em', display: 'flex' };

export const paymentRequestButtonStyle = {
  borderRadius: '4px',
  margin: '0 auto 2em',
  maxWidth: '500px',
  width: '75%'
};

export const cardElementStyle = {
  paddingBottom: '2em'
};

export const errorTextStyle = { color: Style.color.error };

export const Header = styled.h1`
  && {
    text-align: start;
    font-weight: 500;
    color: ${Style.color.primary};
  }
`;
