const linkRegex = /\[.*?\]\((https?:\/\/.*?)\)/g;

const validCharactersRegex = /^[\s[\]/a-zA-Z0-9.,!?'"#*()\-:;]+$/;

export const containsValidCharacters = (markdownText: string): boolean => {
  return validCharactersRegex.test(markdownText);
};

export const isValidMarkdown = (optionalTerms: string | null): boolean => {
  if (optionalTerms !== null) {
    const isValidContent = containsValidCharacters(optionalTerms);
    const hasValidLinks = optionalTerms.match(linkRegex);

    return (
      isValidContent &&
      hasValidLinks !== null &&
      optionalTerms.trim().length > 0
    );
  }
  return false;
};
