import { SetStateAction } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as SearchImage } from '../icons/search-16.svg';
import { ReactComponent as CloseIcon } from '../icons/close_16x16.svg';
import { Style } from '../Styles';

export type InputChangeEvent = {
  target: { value: SetStateAction<string> };
};

type SearchBarProps = {
  placeholder: string;
  handleChange: (event: InputChangeEvent) => void;
  value: string;
};

export const SearchBar = (props: SearchBarProps) => {
  function clearSearch() {
    props.handleChange({
      target: {
        value: ''
      }
    });
  }

  return (
    <SearchContainer>
      <SearchImage
        style={{
          position: 'absolute',
          left: '1em',
          top: '1em',
          marginTop: '1px'
        }}
        aria-hidden={true}
      />
      <SearchInput
        type={'search'}
        placeholder={props.placeholder}
        onChange={props.handleChange}
        value={props.value}
      />
      <SearchCloseIcon onClick={clearSearch} hidden={!props.value} />
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  && {
    position: relative;
    padding: 0;
    width: 18.25em;
    @media (max-width: 360px) {
      width: 100%;
    }
  }
`;

const SearchInput = styled.input`
  && {
    -webkit-appearance: none;
    font-family: ${Style.fontFamily};
    color: ${Style.color.primary};
    height: 50px;
    width: 100%;
    margin: 0;
    padding-left: 3em;
    padding-right: 2.5em;
    font-size: 1em;
    border: 1px ${Style.color.gray4} solid;
    border-radius: 4px;

    &:hover {
      border-color: ${Style.color.hover};
    }

    &&::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }

    &&::placeholder {
      color: ${Style.color.gray4};
    }
  }
`;

const SearchCloseIcon = styled(CloseIcon)<{ hidden: boolean }>`
  && {
    position: absolute;
    right: 0.95em;
    cursor: pointer;
    height: 100%;
    ${(props) => {
      return props.hidden ? 'display: none;' : '';
    }}
`;
