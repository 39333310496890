import { errorTextStyle } from '@fpc/common/Styles';

interface IPaymentErrorMessageProps {
  errorMessage: string;
}

const PaymentErrorMessage = (props: IPaymentErrorMessageProps) => {
  const { errorMessage } = props;

  return errorMessage !== '' ? (
    <div data-testid="payment-error" role={'alert'} style={errorTextStyle}>
      {errorMessage}
    </div>
  ) : (
    <></>
  );
};
export default PaymentErrorMessage;
