import React, { ReactElement, useContext, useState } from 'react';
import { PayuPaymentRequest } from '@fpc/api/paymentapp/MakePayuPayment';
import { buttonContinueToDeposit } from '@fpc/common';
import { ContinueButton } from '@fpc/checkout/payu/Style';
import { PayuCheckoutContext } from '@fpc/reactutils/checkoutContextProvider';

interface CheckoutContinueButtonProps {
  handlePayment: (
    paymentRequest: PayuPaymentRequest,
    digitalSignature: string
  ) => Promise<void>;
  paymentErrorMessage: ReactElement;
}

export const CheckoutContinueButton: React.FC<CheckoutContinueButtonProps> = ({
  handlePayment,
  paymentErrorMessage
}) => {
  const { tokens, redirectUrl } = useContext(PayuCheckoutContext);

  const [isRedirection, setRedirection] = useState(false);

  const paymentInfoToken = JSON.parse(tokens.paymentInfoToken)?.paymentInfo;

  const handleRedirection = async (event: any) => {
    event.preventDefault();

    setRedirection(true);

    const paymentRequest: PayuPaymentRequest = {
      encryptedCvv: null,
      token: null,
      paymentInfo: paymentInfoToken,
      merchantSiteUrl: redirectUrl
    };

    try {
      await handlePayment(paymentRequest, tokens.digitalSignature!);
    } finally {
      setRedirection(false);
    }
  };

  return (
    <div>
      <ContinueButton
        id="payu-checkout-continue-button"
        disabled={isRedirection}
        data-testid="payu-checkout-continue-button"
        onClick={handleRedirection}
      >
        {buttonContinueToDeposit()}
      </ContinueButton>
      {paymentErrorMessage}
    </div>
  );
};
