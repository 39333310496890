import { StripeError, StripeErrorType } from '@stripe/stripe-js';
import { traceId } from '@fpc/reactutils/TraceIdContext';
import {
  enterCustomAction,
  leaveCustomAction,
  log,
  reportCustomError
} from '@fpc/common/monitoring/shared';

export async function observedFetch(
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> {
  const request = typeof input === 'string' ? new Request(input, init) : input;
  const actionId = enterCustomAction(request.url);

  return Boolean(actionId)
    ? collectMetricsForFetchOperation(request, actionId)
    : fetch(request);
}

export async function observedStripe<T extends { error?: StripeError }>(
  callback: () => Promise<T>,
  actionDescription: string
): Promise<T> {
  const actionName = `${actionDescription} - Stripe`;
  const actionId = enterCustomAction(actionName);

  return Boolean(actionId)
    ? collectMetricsForStripeOperation(callback, actionId, actionName)
    : callback();
}

async function collectMetricsForFetchOperation(
  request: Request,
  actionId: number
): Promise<Response> {
  log(
    `Collecting Metric for ${request.method} ${request.url}. Action: ${actionId}`
  );

  const actionProperties: Record<string, string> = {
    fpp_request_url: request.url,
    fpp_request_method: request.method,
    fpp_domain: window.location.hostname,
    fpp_trace: traceId
  };

  try {
    const response = await fetch(request);
    actionProperties.fpp_status_code = response.status.toString();

    if (!response.ok) {
      reportCustomError(
        actionId,
        `HTTP ${response.status}: ${request.method} ${request.url}`,
        `Request could not be processed successfully`
      );
    }

    return response;
  } catch (error) {
    reportCustomError(
      actionId,
      `HTTP 500: ${request.method} ${request.url}`,
      error instanceof Error ? error.message : 'Unknown error'
    );

    actionProperties.fpp_status_code = '500';

    throw error;
  } finally {
    leaveCustomAction(actionId, actionProperties);
  }
}

async function collectMetricsForStripeOperation<
  T extends { error?: StripeError }
>(
  callback: () => Promise<T>,
  actionId: number,
  actionName: string
): Promise<T> {
  log(`Collecting Metric for ${actionName}. Action: ${actionId}`);

  const actionProperties: Record<string, string> = {
    fpp_domain: window.location.hostname,
    fpp_trace: traceId,
    fpp_status_code: '200',
    fpp_request_url: actionName
  };

  try {
    const result = await callback();

    if (result?.error) {
      reportCustomError(
        actionId,
        `${actionName} Error: ${result.error.code}`,
        `${result.error.decline_code}`
      );

      actionProperties.fpp_status_code = mapStripeErrorToStatusCode(
        result.error
      );
      actionProperties.fpp_error_code = result.error.code ?? '';
      actionProperties.fpp_decline_code = result.error.decline_code ?? '';
    }

    return result;
  } catch (error) {
    reportCustomError(
      actionId,
      `${actionName} Error: Exception Occurred`,
      error instanceof Error ? error.message : 'Unknown error'
    );

    actionProperties.fpp_status_code = '500';

    throw error;
  } finally {
    leaveCustomAction(actionId, actionProperties);
  }
}

const stripeErrorToStatusCodeMap: Record<StripeErrorType, string> = {
  api_connection_error: '503',
  api_error: '500',
  authentication_error: '401',
  card_error: '402',
  idempotency_error: '409',
  invalid_request_error: '400',
  rate_limit_error: '429',
  validation_error: '400'
};

function mapStripeErrorToStatusCode(error: StripeError): string {
  return stripeErrorToStatusCodeMap[error.type] ?? '500';
}
