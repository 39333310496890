import { statusReject } from '../statusReject';
import { CheckoutTokens } from '@fpc/reactutils/checkoutContextProvider';
import { MitTokens } from '@fpc/reactutils/mitContextProvider';
import { PAYMENT_INITIATOR } from '@fpc/common';
import {
  PaymentIntentConfirmRequest,
  PaymentIntentRequest
} from './PaymentIntentCreationRequest';
import { traceId } from '@fpc/reactutils/TraceIdContext';
import { HeadersInitBuilder } from '@fpc/utils/headerBuilder';
import { observedFetch } from '@fpc/common/monitoring/utils';

export interface AuthPaymentRequest {
  checkoutTokens: CheckoutTokens | MitTokens;
  stripeCustomerId: string;
  paymentMethodId: string | null;
  paymentMethodType: string;
  isSavePaymentMethod: boolean;
  setPaymentMethodAsDefault: boolean;
  fordCustomerId: string;
  paymentInitiator: PAYMENT_INITIATOR;
  isPreAuth?: boolean;
  bffBaseUrl?: string;
}

export interface PaymentResponse {
  paymentIntentClientSecret: string;
  paymentIntentId: string;
  status: string;
  merchantAccountId: string;
  paymentMethodDetails?: {
    type: string;
    card?: {
      brand: string;
      expiryMonth: string;
      expiryYear: string;
      lastFourDigits: string;
      walletType?: string;
    };
    usBankAccount?: {
      bankName: string;
      lastFourDigits: string;
    };
  };
  stripeConnectAccountId?: string;
}

export interface MultiMerchantPaymentResponse extends PaymentResponse {
  status: string;
  data: {
    merchantId: string;
    confirmationId: string;
    paymentIntentId: string;
    status: string;
  }[];
}

export async function makeAuthenticatedPayment(
  request: AuthPaymentRequest
): Promise<PaymentResponse> {
  return fetchAuthPayment(request)
    .then((res) => {
      if (res.ok) {
        return res.json().then((data) => {
          return data;
        });
      } else if (res.status === 401) {
        return Promise.reject(statusReject(res));
      } else {
        return res.json().then((data) => {
          return Promise.reject(data);
        });
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function fetchAuthPayment(request: AuthPaymentRequest) {
  const payUrl = request.bffBaseUrl
    ? `${request.bffBaseUrl}${process.env.PAYMENT_APP_APIGEE_BASE_PATH}/api/v2`
    : `${process.env.PAYMENT_APP_SERVER}/api`;
  const authHeader = !request.bffBaseUrl
    ? { Authorization: 'Bearer ' + request.checkoutTokens.bearerToken }
    : undefined;
  const paymentInfoObj = !request.bffBaseUrl
    ? { paymentInfoToken: request.checkoutTokens.paymentInfoToken }
    : {
        paymentInfoJson: request.checkoutTokens.paymentInfoToken
      };

  let authUrl =
    request.paymentInitiator === PAYMENT_INITIATOR.CUSTOMER
      ? 'authenticated-payment'
      : 'authenticated-payment-merchant';

  return observedFetch(`${payUrl}/${authUrl}`, {
    method: 'POST',
    headers: {
      ...HeadersInitBuilder(),
      ...authHeader
    },
    body: JSON.stringify({
      ...paymentInfoObj,
      stripeCustomerId: request.stripeCustomerId,
      paymentMethodId: request.paymentMethodId,
      fordCustomerId: request.fordCustomerId,
      paymentMethodType: request.paymentMethodType.toUpperCase(),
      setPaymentMethodAsDefault: request.setPaymentMethodAsDefault,
      isPaymentMethodDetach: !request.isSavePaymentMethod,
      isPreAuth: request.isPreAuth
    })
  });
}

export async function confirmPaymentIntentConnectAccount(
  paymentIntent: PaymentIntentConfirmRequest,
  bearerToken?: string,
  bffBaseUrl?: string | null
): Promise<PaymentResponse> {
  return getResponse(paymentIntent, 'confirm-payment', bearerToken, bffBaseUrl);
}

async function getResponse(
  paymentIntent: PaymentIntentConfirmRequest | PaymentIntentRequest,
  url: string,
  bearerToken?: string,
  bffBaseUrl?: string | null
): Promise<PaymentResponse> {
  let targetServer, authHeader;

  if (bffBaseUrl) {
    targetServer = `${bffBaseUrl}${process.env.PAYMENT_APP_APIGEE_BASE_PATH}`;
    authHeader = '';
  } else {
    targetServer = process.env.PAYMENT_APP_SERVER;
    authHeader = 'Bearer ' + bearerToken;
  }

  return observedFetch(`${targetServer}/api/${url}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Traceparent: traceId,
      Authorization: `${authHeader}`
    },
    body: JSON.stringify(paymentIntent)
  })
    .then((res) => {
      if (res.ok) {
        return res.json().then((data) => {
          let response: PaymentResponse = {
            paymentIntentClientSecret: data['client_secret'],
            paymentIntentId: data['id'],
            status: data['status'],
            merchantAccountId: data['merchantAccountId']
          };
          return response;
        });
      } else {
        return Promise.reject(statusReject(res));
      }
    })
    .catch((err) => {
      console.warn(
        'Error Confirming payment intent for connect account: ',
        err
      );
      return Promise.reject(err);
    });
}
