import {
  CheckoutInput,
  InputGridArea,
  InputLabel
} from '@fpc/common/components/InputComponents';
import i18n from '@fpc/common/i18n';
import {
  Button,
  buttonPayNow,
  paymentButtonProcessing,
  translationKeys
} from '@fpc/common';
import { IconsContainer, TextInput } from '@fpc/checkout/payu/Style';
import CardBrandIcons from '@fpc/checkout/payu/CardBrandIcons';
import PaymentErrorMessage from '@fpc/checkout/payu/components/PaymentErrorMessage';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { PAYU_SUPPORTED_CARDS_BY_CURRENCY } from '@fpc/checkout/payu/PayuCardConfig';
import {
  getDefaultCardBrandIcons,
  validateSupportedCards
} from '@fpc/checkout/payu/PayuCardsValidation';
import {
  appendPayuScript,
  PayuFieldObject,
  tokenGeneration
} from '@fpc/checkout/payu/PayuFields';
import { PayuPaymentRequest } from '@fpc/api/paymentapp/MakePayuPayment';
import { PayuCheckoutContext } from '@fpc/reactutils/checkoutContextProvider';

export interface CheckoutFormProps {
  handlePayment: (
    paymentRequest: PayuPaymentRequest,
    digitalSignature: string
  ) => Promise<void>;
  paymentErrorMessage: ReactElement;
}

export const CheckoutForm: React.FC<CheckoutFormProps> = ({
  handlePayment,
  paymentErrorMessage
}) => {
  const { transaction, tokens, redirectUrl } = useContext(PayuCheckoutContext);

  const [userName, setUserName] = useState('');
  const publicKey = transaction.pspAuthKey.publicKey;
  const [isValidName, setValidName] = useState(false);
  const [isValidCardNumber, setIsValidCardNumber] = useState(false);
  const [isValidExp, setIsValidExp] = useState(false);
  const [isValidCvv, setIsValidCvv] = useState(false);
  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const [isPay, setPay] = useState(false);
  const paymentInfoToken = JSON.parse(tokens.paymentInfoToken).paymentInfo;

  const [icons, setIcons] = useState<string[]>([]);

  const updateDefaultCardIcons = (): void => {
    const cardBrands: string[] = getDefaultCardBrandIcons(transaction.currency);
    setIcons(cardBrands);
  };

  const updateCardIconsOnCardNumberChange = (event: PayuFieldObject): void => {
    const supportedCardIcons =
      PAYU_SUPPORTED_CARDS_BY_CURRENCY[transaction.currency.toUpperCase()];
    if (
      event.brand !== undefined &&
      supportedCardIcons.includes(event.brand.toUpperCase())
    ) {
      setIcons([event.brand]);
    } else {
      updateDefaultCardIcons();
    }
  };

  useEffect(() => {
    appendPayuScript(
      publicKey,
      handleCardNumberChange,
      handleExpChange,
      handleCvvChange,
      transaction.currency,
      () => {}
    );

    updateDefaultCardIcons();
  }, []);

  useEffect(() => {
    setPay(isValidName && isValidCvv && isValidExp && isValidCardNumber);
  }, [isValidName, isValidCvv, isValidExp, isValidCardNumber]);

  const handleCardNumberChange = (event: PayuFieldObject) => {
    updateCardIconsOnCardNumberChange(event);

    if (event.error === undefined && !event.empty) {
      setIsValidCardNumber(validateSupportedCards(event, transaction.currency));
    } else {
      setIsValidCardNumber(false);
    }
  };

  const handleExpChange = (event: PayuFieldObject) => {
    if (event.error === undefined && !event.empty) {
      setIsValidExp(true);
    } else {
      setIsValidExp(false);
    }
  };

  const handleCvvChange = (event: PayuFieldObject) => {
    if (event.error === undefined && !event.empty) {
      setIsValidCvv(true);
    } else {
      setIsValidCvv(false);
    }
  };

  function handleChange(event: any) {
    event.preventDefault();
    setUserName(event.target.value);
    if (event.target.value !== '') {
      setValidName(true);
    } else {
      setValidName(false);
    }
  }

  function handleKeyPress(event: any) {
    const pattern = /^[a-zA-Z\s]{0,50}$/;
    if (pattern.test(event.key)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  function handleBlur(event: any) {
    event.preventDefault();
    const element = document.getElementById('name-help-text');

    if (event.target.value == '') {
      if (element) {
        element.innerHTML = i18n.t<string>(
          translationKeys.checkout.emptyNameMessage
        );
      }
    } else {
      if (element) {
        element.innerHTML = '';
      }
    }
  }

  async function handleSubmit(event: any) {
    event.preventDefault();

    setPay(false);
    setPaymentProcessing(true);

    const additionalData = {
      holder_name: userName // This field is mandatory
    };

    const tokenResult: any = await tokenGeneration(additionalData);

    const parsedToken = JSON.parse(tokenResult);
    const paymentRequest: PayuPaymentRequest = {
      encryptedCvv: parsedToken.encrypted_cvv,
      token: parsedToken.token,
      paymentInfo: paymentInfoToken,
      merchantSiteUrl: redirectUrl
    };
    try {
      await handlePayment(paymentRequest, tokens.digitalSignature!);
    } finally {
      setPay(true);
      setPaymentProcessing(false);
    }
  }

  return (
    <form id="payu-checkout-form" data-testid="payu-checkout-form">
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '10px',
          gridAutoRows: 'minmax(50px, auto)'
        }}
      >
        <InputGridArea
          style={{ gridArea: '1 / 1 / auto / -1', paddingTop: '30px' }}
        >
          <InputLabel htmlFor="cardholder-name">
            {i18n.t<string>(translationKeys.checkout.fullName)}
          </InputLabel>
          <div
            className="input-group"
            style={{ display: '', position: 'relative' }}
          >
            <CheckoutInput
              className="form-control"
              id="cardholder-name"
              data-payu="chname"
              style={{
                padding: '0.9em 0',
                borderRadius: '0.25rem'
              }}
            >
              <TextInput
                id="cardholder-name"
                placeholder={i18n.t<string>(
                  translationKeys.checkout.nameOnCard
                )}
                data-testid={'cardholder-name'}
                value={userName}
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                onBlur={handleBlur}
              />
            </CheckoutInput>
            <span
              className="helper-text"
              id="name-help-text"
              data-testid="name-help-text"
            />
          </div>
        </InputGridArea>
        <InputGridArea style={{ gridArea: '2 / 1 / auto / -1' }}>
          <InputLabel htmlFor="card-number">
            {i18n.t<string>(translationKeys.checkout.cardNumber)}
          </InputLabel>
          <div
            className="input-group"
            style={{ display: '', position: 'relative' }}
          >
            <CheckoutInput
              className="form-control"
              style={{
                borderRadius: '.25rem',
                padding: '0.9em 0.5em',
                display: 'flex'
              }}
            >
              <div
                id="card-number"
                data-payu="ccn"
                style={{ width: '75%' }}
              ></div>

              <IconsContainer data-testid="card-icons">
                {icons && <CardBrandIcons icons={icons} />}
              </IconsContainer>
            </CheckoutInput>
          </div>
          <span className="helper-text" id="ccn-help" />
        </InputGridArea>

        <InputGridArea style={{ gridArea: '3 / 1 / auto / 1' }}>
          <InputLabel htmlFor="card-expiry">
            {i18n.t<string>(translationKeys.checkout.expirationDate)}
          </InputLabel>
          <CheckoutInput
            className="form-control"
            id="card-expiry"
            data-payu="exp"
            style={{
              borderBottomLeftRadius: '.25rem',
              borderTopLeftRadius: '.25rem',
              borderBottomRightRadius: '.25rem',
              borderTopRightRadius: '.25rem',
              padding: '0.9em 0.5em'
            }}
          />
          <span className="helper-text" id="exp-help" />
        </InputGridArea>

        <InputGridArea style={{ gridArea: '3 / 2 / auto / 2' }}>
          <InputLabel htmlFor="card-cvv">
            {i18n.t<string>(translationKeys.checkout.securityCode)}
          </InputLabel>
          <CheckoutInput
            className="form-control bluesnap-checkout-input-border"
            id="card-cvv"
            data-payu="cvv"
            style={{
              borderBottomLeftRadius: '.25rem',
              borderTopLeftRadius: '.25rem',
              borderBottomRightRadius: '.25rem',
              borderTopRightRadius: '.25rem',
              padding: '0.9em 0.5em'
            }}
          />
          <span className="helper-text" id="cvv-help" />
        </InputGridArea>
      </div>
      {paymentErrorMessage}
      <div style={{ gridArea: '4 / 1 / auto / -1' }}>
        <Button
          id="pay-button"
          disabled={!isPay}
          data-testid={'pay-button'}
          onClick={handleSubmit}
          style={{ marginTop: '1em' }}
        >
          {isPaymentProcessing
            ? paymentButtonProcessing()
            : buttonPayNow(transaction.amount, transaction.currency)}
        </Button>
      </div>
    </form>
  );
};
