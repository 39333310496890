import ReactMarkdown from 'react-markdown';
import React from 'react';
import DOMPurify from 'dompurify';
import { isValidMarkdown } from '@fpc/reactutils/isValidMarkdown';

interface MarkdownProps {
  optionalTerms: string | null;
}

const MarkdownUtils: React.FC<MarkdownProps> = ({ optionalTerms }) => {
  if (optionalTerms !== null && isValidMarkdown(optionalTerms)) {
    const sanitizedMarkdown = DOMPurify.sanitize(optionalTerms);
    return (
      <>
        <ReactMarkdown
          components={{
            a: LinkRenderer,
            p: ParagraphRenderer
          }}
        >
          {sanitizedMarkdown}
        </ReactMarkdown>
      </>
    );
  } else {
    console.error('Markdown contains invalid string.');
    return null;
  }
};

const LinkRenderer: React.FC<{ href?: string }> = (props: any) => {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

const ParagraphRenderer: React.FC<{}> = (props: any) => {
  return <>{props.children}</>;
};

export default MarkdownUtils;
