const baseLinkAddress = `${process.env.ASSETS}/documents/`;

// US
export const enUSTermsLink = `${baseLinkAddress}paymentTC-enUS.pdf`;
export const esUSTermsLink = `${baseLinkAddress}paymentTC-esUS.pdf`;
export const frUSTermsLink = `${baseLinkAddress}paymentTC-frUS.pdf`;
// AT (Austria)
export const deATTermsLink = `${baseLinkAddress}PaymentTsAndCsDE-AT.pdf`;
// BE (Belgium)
export const frBETermsLink = `${baseLinkAddress}PaymentTsAndCsFR-BEV1.0.pdf`;
export const nlBETermsLink = `${baseLinkAddress}PaymentTsAndCsNL-BEV1.0.pdf`;
// CA (Canada)
export const frCATermsLink = `${baseLinkAddress}paymentTC-frCA.pdf`;
export const enCATermsLink = `${baseLinkAddress}paymentTC-enCA.pdf`;
// CH (Switzerland)
export const deCHTermsLink = `${baseLinkAddress}PaymentTsAndCsDE-CH.pdf`;
export const frCHTermsLink = `${baseLinkAddress}PaymentTsAndCsFR-CH.pdf`;
export const itCHTermsLink = `${baseLinkAddress}PaymentTsAndCsIT-CH.pdf`;
// CZ (Czechia)
export const csCZTermsLink = `${baseLinkAddress}PaymentTsAndCS-CZ.pdf`;
// DE (Germany)
export const deDETermsLink = `${baseLinkAddress}PaymentTsAndCsGermanV1.pdf`;
// DK (Denmark)
export const daDKTermsLink = `${baseLinkAddress}PaymentTsAndCsDA-DKV1.1.pdf`;
// ES (Spain)
export const esESTermsLink = `${baseLinkAddress}PaymentTsAndCsES-EsV1.0.pdf`;
// FI (Finland)
export const fiFITermsLink = `${baseLinkAddress}PaymentTsAndCsFI-FIV1.1.pdf`;
// FR (France)
export const frFRTermsLink = `${baseLinkAddress}PaymentTsAndCsFR-FRV1.0.pdf`;
// GB (Great Britain)
export const enGBTermsLink = `${baseLinkAddress}PaymentTsAndCsEN-GBV1.0.pdf`;
// GR (Greece)
export const elGRTermsLink = `${baseLinkAddress}PaymentTsAndCsEL-GR.pdf`;
// HU (Hungary)
export const huHUTermsLink = `${baseLinkAddress}PaymentTsAndCsHU-HU.pdf`;
// IE (Ireland)
export const enIETermsLink = `${baseLinkAddress}PaymentTsAndCsEN-IEV1.0.pdf`;
// IT (Italy)
export const itITTermsLink = `${baseLinkAddress}PaymentTsAndCsIT-ITV1.pdf`;
// LU (Luxembourg)
export const frLUTermsLink = `${baseLinkAddress}PaymentTsAndCsFR-LUV1.0.pdf`;
// NL (Netherlands)
export const nlNLTermsLink = `${baseLinkAddress}PaymentTsAndCsNL-NLV1.0.pdf`;
// NO (Norway)
export const noNOTermsLink = `${baseLinkAddress}PaymentTsAndCsNO-NOV1.0.pdf`;
// PL (Poland)
export const plPLTermsLink = `${baseLinkAddress}PaymentTsAndCsPL-PLV1.0.pdf`;
// PT (Portugal)
export const ptPTTermsLink = `${baseLinkAddress}PaymentTsAndCsPT-PTV1.pdf`;
// RO (Romania)
export const roROTermsLink = `${baseLinkAddress}PaymentTsAndCsRO-RO.pdf`;
// SE (Sweden)
export const svSETermsLink = `${baseLinkAddress}PaymentTsAndCsSV-SE.pdf`;
// AU (Australia)
export const enAUTermsLink = `${baseLinkAddress}paymentTC-enAU.pdf`;
// NZ (New Zealand)
export const enNZTermsLink = `${baseLinkAddress}paymentTC-enNZ.pdf`;

export function resolveTCLink(locale: string): string {
  switch (String(locale).toLowerCase()) {
    case 'en-us':
      return enUSTermsLink;
    case 'es-us':
      return esUSTermsLink;
    case 'de-at':
      return deATTermsLink;
    case 'fr-be':
      return frBETermsLink;
    case 'nl-be':
      return nlBETermsLink;
    case 'en-ca':
      return enCATermsLink;
    case 'fr-ca':
      return frCATermsLink;

    case 'de-ch':
      return deCHTermsLink;
    case 'fr-ch':
      return frCHTermsLink;
    case 'it-ch':
      return itCHTermsLink;
    case 'cs-cz':
      return csCZTermsLink;
    case 'de-de':
      return deDETermsLink;
    case 'da-dk':
      return daDKTermsLink;
    case 'es-es':
      return esESTermsLink;
    case 'fi-fi':
      return fiFITermsLink;
    case 'fr-fr':
      return frFRTermsLink;
    case 'en-gb':
      return enGBTermsLink;
    case 'el-gr':
      return elGRTermsLink;
    case 'hu-hu':
      return huHUTermsLink;
    case 'en-ie':
      return enIETermsLink;
    case 'it-it':
      return itITTermsLink;
    case 'fr-lu':
      return frLUTermsLink;
    case 'nl-nl':
      return nlNLTermsLink;
    case 'no-no':
      return noNOTermsLink;
    case 'pl-pl':
      return plPLTermsLink;
    case 'pt-pt':
      return ptPTTermsLink;
    case 'ro-ro':
      return roROTermsLink;
    case 'sv-se':
      return svSETermsLink;
    case 'en-au':
      return enAUTermsLink;
    case 'en-nz':
      return enNZTermsLink;
    default:
      return enUSTermsLink;
  }
}
