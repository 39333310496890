import {
  PaymentMethodAchAcssDebit,
  PaymentMethodCard,
  PaymentMethodInfo,
  PaymentMethodSepaDebit,
  PaymentMethodUsBank
} from '@fpc/api/paymentapp';
import {
  ACSS_DEBIT,
  BACS_DIRECT_DEBIT,
  CARD_PAYMENT_METHOD,
  SEPA_PAY_METHOD,
  US_BANK_PAYMENT_METHOD
} from '../checkout';
import { StripeTransactionDetails } from '@fpc/common/transactionInterfaces';
import { PaymentMethodDetail } from '@fpc/utils/dispatchEvent';
import { getPaymentMethodDetails } from '@fpc/api/customerapp/GetPaymentMethodDetails';
import { MultiMerchantStripeTransactionDetails } from '@fpc/checkout/features/multi-merchant/types/PaymentInfoToken';

export type StripeTransactionDetailsMetaType =
  | StripeTransactionDetails
  | MultiMerchantStripeTransactionDetails
  | null;

export function sortPaymentMethodsByDefault(
  paymentMethods: PaymentMethodInfo[],
  shouldApplyExclude: boolean
): PaymentMethodInfo[] {
  const defaultPaymentMethods: PaymentMethodInfo[] = [];
  const notDefaultPaymentMethods: PaymentMethodInfo[] = [];

  for (const paymentMethod of paymentMethods) {
    if (paymentMethod.isDefault) {
      defaultPaymentMethods.push(paymentMethod);
    } else {
      notDefaultPaymentMethods.push(paymentMethod);
    }
  }
  return [...defaultPaymentMethods, ...notDefaultPaymentMethods];
}

export function filterPaymentMethodsByTypes(
  supportedPaymentMethodTypes: string[],
  isPreAuth: boolean,
  noCreditCards: boolean,
  achEnabled?: boolean,
  variableDebitEnabled?: boolean,
  isZeroAmountForMit?: boolean
): (paymentMethod: PaymentMethodInfo) => boolean {
  return (paymentMethod) => {
    let upperCaseTypes = supportedPaymentMethodTypes.map((type) =>
      type.toUpperCase()
    );

    if (!isZeroAmountForMit && isPreAuth) {
      upperCaseTypes = upperCaseTypes.filter((type) => type === 'CARD');
    }
    if (noCreditCards) {
      upperCaseTypes = upperCaseTypes.filter((type) => type !== 'CARD');
    }
    //Default to CARD when invalid params are passed
    if (noCreditCards && achEnabled === false && !variableDebitEnabled) {
      upperCaseTypes.push('CARD');
    }

    return upperCaseTypes.includes(paymentMethod.type.toUpperCase());
  };
}

export function filterPaymentMethodTypes(
  paymentMethods: string[],
  paymentMethodTypeToFilter: string,
  paymentMethodsExcluded?: string[]
) {
  return paymentMethods.filter(
    (paymentMethod) =>
      !paymentMethodsExcluded?.includes(paymentMethod) &&
      paymentMethod.toUpperCase() !== paymentMethodTypeToFilter.toUpperCase()
  );
}

export function filterGooglePayApplePayFromWallet(cards: PaymentMethodInfo[]) {
  return cards.filter((card) => {
    if (card.type === 'card') {
      let singleCard = card.paymentMethod as PaymentMethodCard;
      let walletType = singleCard.walletType;
      return walletType !== 'google_pay' && walletType !== 'apple_pay';
    }
    return true;
  });
}

export function isAchEnabled(
  transactionDetails: StripeTransactionDetailsMetaType
): boolean {
  return !!transactionDetails?.paymentMethodTypes.find((value) => {
    return (
      value.toLowerCase() === US_BANK_PAYMENT_METHOD || value === ACSS_DEBIT
    );
  });
}

export function isGuestpay(
  transactionDetails: StripeTransactionDetailsMetaType,
  achNoPay: boolean | undefined
): boolean | undefined {
  return (
    transactionDetails?.paymentMethodTypes.length === 1 &&
    !!transactionDetails?.paymentMethodTypes.find((value) => {
      return value.toLowerCase() === US_BANK_PAYMENT_METHOD;
    }) &&
    achNoPay
  );
}

export function disableTermsAndCondition(
  transaction: StripeTransactionDetails | MultiMerchantStripeTransactionDetails,
  isGuestPay: boolean | undefined
): boolean | undefined {
  const currenciesToDisableTC = ['thb', 'aud', 'nzd'];
  return (
    currenciesToDisableTC.includes(transaction.currency.toLowerCase()) ||
    isGuestPay
  );
}

export function isVariableDebitEnabled(
  transactionDetails: StripeTransactionDetailsMetaType
): boolean {
  return !!transactionDetails?.paymentMethodTypes.find((value) => {
    return (
      value.toLowerCase() === SEPA_PAY_METHOD ||
      value.toLowerCase() === BACS_DIRECT_DEBIT
    );
  });
}

export function filterPaymentMethodByCardBrand(
  paymentMethods: PaymentMethodInfo[],
  blockedCardBrands: string[]
) {
  let blockedCardsMap = new Map<string, string[]>([
    ['american_express', ['amex']],
    ['visa', ['visa']],
    ['mastercard', ['mastercard']],
    [
      'discover_global_network',
      ['discover', 'diners', 'jcb', 'unionpay', 'elo']
    ]
  ]);

  let filteredCardBrands = blockedCardBrands.reduce((acc: string[], cur) => {
    let keys: string[] = blockedCardsMap.get(cur.toLowerCase()) || [];
    return [...acc, ...keys];
  }, []);
  return paymentMethods.filter((item) => {
    if (item.type === 'card') {
      let result = item.paymentMethod as PaymentMethodCard;

      return !filteredCardBrands?.includes(result.type);
    }
    return true;
  });
}

export function isAcssDebitPresent(paymentMethods: string[]): boolean {
  return paymentMethods.includes(ACSS_DEBIT);
}

export function hasCardElement(paymentMethods: string[]): boolean {
  return paymentMethods.includes(CARD_PAYMENT_METHOD.toUpperCase());
}

export function shouldShowAcssDebitFirst(paymentMethods: string[]): boolean {
  return paymentMethods[0] === ACSS_DEBIT;
}

export async function getPaymentMethodDetailForEvent(
  paymentMethodId: string,
  bearerToken: string | null,
  bffBaseUrl: string | null
): Promise<PaymentMethodDetail | null> {
  let paymentMethodDetail: PaymentMethodDetail | null = null;

  const paymentMethodInfo = await getPaymentMethodDetails(
    paymentMethodId,
    null,
    bearerToken,
    bffBaseUrl
  );
  if (paymentMethodInfo) {
    if (paymentMethodInfo.type === 'card') {
      const paymentMethodCard =
        paymentMethodInfo.paymentMethod as PaymentMethodCard;
      paymentMethodDetail = {
        type: paymentMethodInfo.type,
        card: {
          brand: paymentMethodCard.type,
          expiryMonth: paymentMethodCard.expireMonth.toString(),
          expiryYear: paymentMethodCard.expireYear.toString(),
          lastFourDigits: paymentMethodCard.lastFourDigits,
          ...(paymentMethodCard.walletType && {
            walletType: paymentMethodCard.walletType
          })
        }
      };
    } else if (paymentMethodInfo.type === 'us_bank_account') {
      const paymentMethodUsBank =
        paymentMethodInfo.paymentMethod as PaymentMethodUsBank;
      paymentMethodDetail = {
        type: paymentMethodInfo.type,
        usBankAccount: {
          bankName: paymentMethodUsBank.bankName,
          lastFourDigits: paymentMethodUsBank.lastFourDigits
        }
      };
    } else if (paymentMethodInfo.type === 'sepa_debit') {
      const paymentMethodSepaDebit =
        paymentMethodInfo.paymentMethod as PaymentMethodSepaDebit;
      paymentMethodDetail = {
        type: paymentMethodInfo.type,
        sepaDebit: {
          bankCode: paymentMethodSepaDebit.bankCode,
          lastFourDigits: paymentMethodSepaDebit.lastFourDigits
        }
      };
    } else if (paymentMethodInfo.type === 'acss_debit') {
      const paymentMethodAcssDebit =
        paymentMethodInfo.paymentMethod as PaymentMethodAchAcssDebit;
      paymentMethodDetail = {
        type: paymentMethodInfo.type,
        acssDebit: {
          bankName: paymentMethodAcssDebit.bankName,
          lastFourDigits: paymentMethodAcssDebit.lastFourDigits
        }
      };
    }
  }

  return paymentMethodDetail;
}
