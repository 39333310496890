import React, { ReactElement, useContext } from 'react';
import {
  CHECKOUT_FORM,
  CHECKOUT_REDIRECTION,
  getCheckoutFlowByCurrency
} from '@fpc/checkout/payu/PayuCardConfig';
import { CheckoutPageContainer } from '@fpc/checkout/payu/Style';
import { PayuCheckoutContext } from '@fpc/reactutils/checkoutContextProvider';

interface CheckoutStrategyProps {
  checkoutForm: ReactElement;
  checkoutContinueButton: ReactElement;
}

export const CheckoutLoader: React.FC<CheckoutStrategyProps> = ({
  checkoutForm,
  checkoutContinueButton
}) => {
  const { transaction } = useContext(PayuCheckoutContext);

  const checkoutFlow = getCheckoutFlowByCurrency(transaction.currency);

  return (
    <CheckoutPageContainer
      className="payu-checkout-page"
      data-testid="payu-checkout-page"
    >
      {(() => {
        switch (checkoutFlow) {
          case CHECKOUT_FORM:
            return checkoutForm;
          case CHECKOUT_REDIRECTION:
            return checkoutContinueButton;
          default:
            return checkoutForm;
        }
      })()}
    </CheckoutPageContainer>
  );
};
