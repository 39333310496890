import { statusReject } from '@fpc/api/statusReject';
import { traceId } from '@fpc/reactutils/TraceIdContext';

export interface PayuPaymentRequest {
  token: string | null;
  encryptedCvv: string | null;
  paymentInfo: string;
  merchantSiteUrl: string;
}

export interface PayuPaymentResponse {
  paymentId: string;
  status: string;
  redirectionUrl: string;
}

export const makePayuPayment = async (
  request: PayuPaymentRequest,
  bearerToken: string
): Promise<PayuPaymentResponse> => {
  return fetch(
    `${process.env.PAYMENT_DIGITAL_SIGNATURE_SERVER}/api/payments/payu`,
    {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerToken,
        Traceparent: traceId
      }
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        return Promise.reject(statusReject(res));
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
