import { Currency, formatCurrency, translationKeys } from '@fpc/common';
import { buttonContentsWithSpinner } from '@fpc/common/components/ButtonContentsWithSpinner';
import { errorTextStyle } from '@fpc/common/Styles';
import i18n from '@fpc/common/i18n';
import { ReactElement } from 'react';
import { StripeTransactionDetails } from '@fpc/common/transactionInterfaces';

export function determineButtonProcessingText(
  isPaymentProcessing: boolean,
  isMit?: boolean,
  transactionDynamic?: StripeTransactionDetails
): string | ReactElement {
  if (isPaymentProcessing) {
    const mitSuccessText = isMit
      ? i18n.t<string>(translationKeys.mit.successful)
      : undefined;
    return paymentButtonProcessing(mitSuccessText);
  } else {
    return buttonPayNow(
      transactionDynamic?.amount!,
      transactionDynamic?.currency!
    );
  }
}
export const paymentButtonProcessing = (text?: string) => {
  return text
    ? buttonContentsWithSpinner(text)
    : buttonContentsWithSpinner(
        i18n.t<string>(translationKeys.common.processing)
      );
};

export function buttonPayNow(amount: number, currency: Currency) {
  return i18n.t<string>(translationKeys.checkout.payButton, {
    formattedCurrency: formatCurrency(amount, currency, i18n.language)
  });
}

export function buttonContinue(amount: number, currency: Currency) {
  return i18n.t<string>(translationKeys.checkout.continue, {
    formattedCurrency: formatCurrency(amount, currency, i18n.language)
  });
}

export function buttonNext() {
  return i18n.t<string>(translationKeys.checkout.nextButton);
}

export function buttonContinueToDeposit() {
  return i18n.t<string>(translationKeys.checkout.continueToDeposit);
}

export const errorWaitTime = 30000;

interface StripeElementErrorDisplayProps {
  message: string;
  isGuestPay?: boolean;
}

export function StripeElementErrorDisplay(
  props: StripeElementErrorDisplayProps
) {
  return (
    <>
      {(!props.isGuestPay || (props.isGuestPay && props.message)) && <br />}
      {/* Message does not need i18n as it is generated by Stripe as needed. */}
      <div id={'stripe-alerts'} role={'alert'} style={errorTextStyle}>
        {props.message}
      </div>
      {props.message && <br />}
    </>
  );
}
